import * as React from "react";
import {createElement} from "react";
import {ContainerLayout} from "./ContainerLayout";
import notFound from "../assets/undraw_not_found_60pq.svg";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {messages} from "../i18n";
import {BlockSvg} from "./BlockSvg";
import {useHistory} from "react-router";

export function NotFound() {
    const history = useHistory();
    const path = window.location.pathname;
    return(
        <ContainerLayout>
            <BlockSvg src={notFound} />
            <Box p={4} />
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">{messages.notFound.title}</Typography>
                    <Typography variant="body1" align="center">{messages.notFound.info(path)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                        <Button onClick={(_e) => history.push('/')}>{messages.notFound.home}</Button>
                    </Typography>
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}
