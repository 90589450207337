import * as React from "react";
import {createElement, useState} from "react";
import {Delivery, QueueItem, QueueStatus} from "../../domain/types";
import {
    Box, Button,
    Grid,
    IconButton,
    makeStyles, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {DateComponent} from "../DateComponent";
import {QueueEventStateComponent} from "./QueueEventStateComponent";
import customTheme, {gs} from "../../theme";
import {LabeledData} from "../LabeledData";
import {messages} from "../../i18n";
import {SignatureComponent} from "../SignatureComponent";
import {ChevronRightIcon, ExpandMoreIcon} from "../../icons";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {userStore} from "../../domain/UserStore";
import {Alert} from "@material-ui/lab";

interface EventsTableProps {
    delivery: Delivery;
    events: QueueItem[];
    reload: () => void;
}

export function EventsTable({events, delivery, reload}: EventsTableProps) {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={{width: customTheme.spacing(1)}}></TableCell>
                    <TableCell style={{width: customTheme.spacing(1)}}>{messages.deliveries.events.executionState}</TableCell>
                    <TableCell>{messages.deliveries.events.type}</TableCell>
                    <TableCell>{messages.deliveries.events.createdAt}</TableCell>
                    <TableCell>{messages.deliveries.events.retryAt}</TableCell>
                    <TableCell>{messages.deliveries.events.deliveredAt}</TableCell>
                    <TableCell>{messages.deliveries.events.expiredAt}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {events.length > 0 && events.map((e) => <EventRow key={e.uuid} event={e} reload={reload} />)}
                {events.length == 0 &&
                <TableRow>
                    <TableCell colSpan={8}>
                        <Box p={2}>
                            <Typography align="center" color="textSecondary">
                            {messages.deliveries.noEvents(delivery.carrierId)}
                            </Typography>
                        </Box>
                    </TableCell>
                </TableRow>
                }
            </TableBody>
        </Table>
    );
}

interface EventRowProps {
    event: QueueItem;
    reload: () => void;
}

function EventRow(props: EventRowProps) {
    const e = props.event;
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [eventRetry, setEventRetry] = useState<boolean>(false);
    const classes = useStyles();

    const handleRetry = (deliveryUuid: string, eventUuid: string) => {
        DeliveriesProvider.retryEvent(userStore.getTenantId(), deliveryUuid, eventUuid).then(() => {
            setEventRetry(true);
            props.reload();
        });
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell><QueueEventStateComponent state={e.status} size="small" /></TableCell>
                <TableCell>{e.eventType}</TableCell>
                <TableCell><DateComponent date={e.created} /></TableCell>
                <TableCell>{e.retry && <DateComponent date={e.retry} />}</TableCell>
                <TableCell>{e.delivered && <DateComponent date={e.delivered} />}</TableCell>
                <TableCell>{e.expired && <DateComponent date={e.expired} />}</TableCell>
            </TableRow>
            {showDetails &&
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell colSpan={6}>
                    <Box paddingTop={4} paddingBottom={4}>
                        <Grid container spacing={gs}>
                            <Grid item sm={6} xs={12}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.failures}>{e.failureCount}</LabeledData>
                                            </Grid>
                                            {e.request &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.request}>{e.request}</LabeledData>
                                            </Grid>
                                            }
                                            {e.response &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.response}>{e.response}</LabeledData>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gs}>
                                            {e.scannedBarcode &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.scannedBarcode}>{e.scannedBarcode}</LabeledData>
                                            </Grid>
                                            }
                                            {e.code &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.code}>{e.code}</LabeledData>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={gs}>
                                            {e.name &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.name}>{e.name}</LabeledData>
                                            </Grid>
                                            }
                                            {e.email &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.email}>{e.email}</LabeledData>
                                            </Grid>
                                            }
                                            {e.phone &&
                                            <Grid item>
                                                <LabeledData label={messages.deliveries.events.phone}>{e.phone}</LabeledData>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Grid container spacing={gs}>
                                    {e.status == QueueStatus.Failed &&
                                    <Grid item xs={12}>
                                        <Typography align="right" component="div">
                                            <Button variant="outlined" onClick={() => handleRetry(e.deliveryId, e.uuid)}>{messages.deliveries.events.retry}</Button>
                                        </Typography>
                                    </Grid>
                                    }
                                    {e.signature &&
                                    <Grid item xs={12}>
                                        <LabeledData label={messages.deliveries.events.signature}>
                                            <SignatureComponent signature={e.signature} isPicture={e.carrierId !== "ups"} />
                                        </LabeledData>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </TableCell>
            </TableRow>
            }
            <Snackbar open={eventRetry} autoHideDuration={10000}>
                <Alert severity="success">{messages.deliveries.events.retrySuccess}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

const useStyles = makeStyles({
    signature: {
        display: 'inline-block',
        maxWidth: '100%'
    }
});
