import * as React from "react";
import {createElement} from "react";
import {Carrier} from "../../domain/types";
import {Checkbox, ListItem, ListItemIcon , ListItemText, List} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default (props: { carriers: Carrier[], checked: Carrier[], setChecked: (carriers: Carrier[]) => void }) => {
    const classes = useStyles();
    const checked = props.checked;
    const setChecked = props.setChecked;

    const handleToggle = (value: Carrier) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <List className={classes.root}>
            {props.carriers.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`;
                return (
                    <ListItem key={value.id} button>
                        <ListItemIcon>
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(value)}
                                checked={checked.indexOf(value) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.id} />
                    </ListItem>
                );
            })}
        </List>
    );
};