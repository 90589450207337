import {AuthLoginPath, authStorage} from "./Auth";
import {Pageable} from "./domain/types";

const BaseHeaders = {
    'Content-Type': 'application/json',
};

export class ApiError {

    constructor(private _code: number|undefined, private _message: string|undefined, private _url: string|undefined) {
    }

    get code() {
        return this._code;
    }

    get message() {
        return this._message;
    }

    get url() {
        return this._url;
    }
}

export class Api {

    static SERVICE_URL = '/api/admin/v1';

    static buildHeaders() {
        if(authStorage.authenticated) {
            return {
                ... BaseHeaders,
                'Authorization': `Bearer ${authStorage.accessToken}`,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }
        return BaseHeaders;
    }

    static handle401s(response: Response) {
        if(response.status == 401) {
            this.redirectToLogin();
        }
    }

    static redirectToLogin() {
        console.error('Unauthorized Request, trying to send user back to login.');
        authStorage.logout();
    }

    static buildUrl(path: string) {
        if(!path.startsWith('/')) {
            path = '/' + path;
        }
        return `${Api.SERVICE_URL}${path}`;
    }

    private static fetchData<T>(url: string, requestBody: RequestInit) {
        return new Promise(async (resolve: (value: T) => void, reject: (error: ApiError) => void) => {
            try {
                return fetch(url, requestBody)
                    .then(async (response) => {
                        this.handle401s(response);
                        if (!response.ok) {
                            const message = await response.text();
                            console.error(`An error occurred, status code ${response.status} with message ${message} on path ${url}.`);
                            reject(new ApiError(response.status, message, url));
                            return;
                        }
                        try {
                            resolve(await response.json() as T);
                        } catch (error) {
                            console.warn('Body is empty. Return nothing.');
                            resolve('' as any);
                        }
                    })
            } catch (e) {
                console.error("Failed to fetch. Please contact support.");
                reject(new ApiError(undefined, e.toString(), url));
            }
        });
    }

    static GET<T>(path: string) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'GET',
            headers: Api.buildHeaders()
        });
    }

    static PUT<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'PUT',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static POST<T>(path: string, object: any) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'POST',
            body: JSON.stringify(object),
            headers: Api.buildHeaders()
        });
    }

    static DELETE<T>(path: string) {
        return Api.fetchData<T>(Api.buildUrl(path), {
            method: 'DELETE',
            headers: Api.buildHeaders()
        });
    }

}
