import * as React from "react";
import {createElement, useEffect, useMemo, useState} from "react";
import {ContainerLayout} from "../ContainerLayout";
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {StatisticsProvider} from "../../domain/StatisticsProvider";
import {userStore} from "../../domain/UserStore";
import {Statistics} from "../../domain/types";
import {Loading} from "../Loading";
import {SimpleDonutChart, SimpleDonutChartData} from "./SimpleDonutChart";
import {gs} from "../../theme";

interface StatisticsViewProps {
}

function objectToChartData(object: any): SimpleDonutChartData {
    const series: number[] = [];
    const labels: string[] = [];
    if(object) {
        const keys = Object.keys(object);
        for(let key of keys) {
            const value = object[key];
            labels.push(key);
            series.push(value);
        }
    }
    return {
        labels: labels,
        series: series
    };
}

export function StatisticsView(props: StatisticsViewProps) {
    const [statistics, setStatistics] = useState<Statistics | undefined>(undefined);

    useEffect(() => {
        StatisticsProvider.compute(userStore.getTenantId()).then(setStatistics);
    }, []);

    const boxTypes: SimpleDonutChartData = useMemo(() => objectToChartData(statistics?.boxTypes), [statistics]);
    const cubes: SimpleDonutChartData = useMemo(() => objectToChartData(statistics?.cubes), [statistics]);
    const states: SimpleDonutChartData = useMemo(() => objectToChartData(statistics?.states), [statistics]);
    const services: SimpleDonutChartData = useMemo(() => objectToChartData(statistics?.services), [statistics]);

    return(
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.statistics.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.statistics.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {statistics == undefined && <Loading />}
                    {statistics &&
                    <Grid container spacing={gs}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">{messages.statistics.charts.boxTypes.title}</Typography>
                            <SimpleDonutChart data={boxTypes} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">{messages.statistics.charts.cubes.title}</Typography>
                            <SimpleDonutChart data={cubes} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">{messages.statistics.charts.states.title}</Typography>
                            <SimpleDonutChart data={states} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5">{messages.statistics.charts.services.title}</Typography>
                            <SimpleDonutChart data={services} />
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}
