import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography
} from "@material-ui/core";
import {messages} from "../../i18n";
import {DateTime} from "luxon";
import {gs} from "../../theme";
import {DatePickerInput} from "../base/DatePickerInput";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {userStore} from "../../domain/UserStore";
import {Delivery} from "../../domain/types";

interface RestoreDeliveryDialogProps {
    delivery: Delivery;
    open: boolean;
    onClose: (refresh?: boolean) => void;
}

export function RestoreDeliveryDialog(props: RestoreDeliveryDialogProps) {
    const {delivery, open, onClose} = props;
    const [until, setUntil] = useState<DateTime | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if(delivery.pickedUp) {
            setUntil(delivery.pickedUp.plus({days: 5}));
        }
    }, [delivery]);

    const handleRestore = useCallback(() => {
        setLoading(true);
        if(until) {
            DeliveriesProvider.restoreDelivery(userStore.getTenantId(), delivery.id, until)
                .then(() => onClose(true))
                .finally(() => setLoading(false));
        }
    }, [delivery, until]);

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>{messages.deliveries.restore.title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <DatePickerInput label={messages.deliveries.restore.until}
                                         value={until} onChange={setUntil} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">{messages.deliveries.restore.info}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>{messages.cancel}</Button>
                <Button color="primary" variant="contained" disabled={!until} onClick={handleRestore}>{messages.deliveries.restore.title}</Button>
            </DialogActions>
            {loading && <LinearProgress variant="indeterminate" />}
        </Dialog>
    );
}
