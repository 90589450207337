import {Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {createElement, useMemo, useRef, useState} from "react";
import {messages} from "../../i18n";
import {ArrowDropDownIcon} from "../../icons";
import {Delivery, DeliveryState} from "../../domain/types";

type DeliveryActionsProps = {
    delivery: Delivery;
    onDeleteDelivery: () => void;
    onRestoreDelivery: () => void;
    onMarkMaxStorageDays: () => void;
}

export const DeliveryActions = (props: DeliveryActionsProps) => {
    const {delivery, onDeleteDelivery, onRestoreDelivery, onMarkMaxStorageDays} = props;
    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    let anchorRef = useRef<HTMLDivElement>(null);

    const togglePopper = () => {
        setPopperOpen(!popperOpen);
    };

    const canRestore = useMemo<boolean>(() => {
        const state = delivery.state;
        return state == DeliveryState.PickedUp || state == DeliveryState.Retrieved;
    }, [delivery]);

    return (
        <ButtonGroup variant="outlined" ref={anchorRef}>
            <Button onClick={onMarkMaxStorageDays}>{messages.deliveries.markMaxStorageDays.title}</Button>
            <Button onClick={togglePopper}><ArrowDropDownIcon/></Button>
            {anchorRef && (
                <Popper open={popperOpen} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 999 }}>
                    <Paper>
                        <ClickAwayListener onClickAway={togglePopper}>
                            <MenuList>
                                <MenuItem key="restoreDelivery" disabled={!canRestore} onClick={onRestoreDelivery}>{messages.deliveries.restore.title}</MenuItem>
                                <MenuItem key="deleteDelivery" onClick={onDeleteDelivery}>{messages.deliveries.delete.title}</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            )}
        </ButtonGroup>
    )
};
