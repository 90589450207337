import * as React from "react";
import {createElement} from "react";
import {IconButton} from "@material-ui/core";
import {ClearIcon} from "../../icons";
import {DatePicker} from "@material-ui/pickers";
import {DateTime} from "luxon";

interface DatePickerInputProps {
    value: DateTime | null;
    label?: string;
    onChange: (value: DateTime | null) => void;
    defaultValue?: DateTime;
}

export function DatePickerInput(props: DatePickerInputProps) {
    const {value, label, onChange, defaultValue} = props;
    return (<DatePicker inputVariant="outlined" fullWidth format="dd.MM.yyyy" disablePast
                        InputProps={{
                            endAdornment: <IconButton onClick={(e) => {
                                e.preventDefault(); e.stopPropagation();
                                onChange(null);
                            }} disabled={!value}><ClearIcon /></IconButton>
                        }}
                        label={label}
                        defaultValue={defaultValue}
                        value={value} onChange={(d) => onChange(d)}  />);
}
