import {createElement, Component} from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import {messages} from "../i18n";

interface ConfirmDialogProps {
    title: string;
    message: string;
    confirmLabel?: string;
    resolve?: () => void;
    reject?: () => void;
}

interface ConfirmDialogState {

}

export class ConfirmDialog extends Component<ConfirmDialogProps, ConfirmDialogState> {

    constructor(props: ConfirmDialogProps) {
        super(props);
        this.state = {};
    }

    private onConfirm() {
        if(this.props.resolve) {
            this.props.resolve();
        }
    }

    private onCancel() {
        if(this.props.reject) {
            this.props.reject();
        }
    }

    render() {
        const {title, message, confirmLabel, resolve} = this.props;
        const open = resolve != undefined;
        return(
            <Dialog open={open} onClose={() => {
                this.onCancel();
            }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => this.onCancel()}>{messages.cancel}</Button>
                    <Button color="primary" variant="contained" onClick={() => this.onConfirm()}>{confirmLabel || messages.confirm}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
