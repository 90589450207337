import {Component, createElement} from "react";

interface Props<T> {
    list: T[];
}

interface States<K> {
    orderAsc: boolean;
    orderBy: K;
}

export abstract class SortableTable<T, K> extends Component<Props<T>, States<K>> {

    protected constructor(props: Props<T>) {
        super(props);
    }

    protected descendingComparator(a: T, b: T, asc: boolean, orderBy: K) {
        return 0;
    }

    protected onSort(sortKey: K) {
        if (this.state.orderBy === sortKey) {
            this.setState({
                orderAsc: !this.state.orderAsc
            });
        } else {
            this.setState({
                orderBy: sortKey
            });
        }
    }

    protected getSorted() {
        const {list} = this.props;
        return list ? list.sort((a, b) => this.descendingComparator(a, b, this.state.orderAsc, this.state.orderBy)) : undefined;
    }

    render() {
        return <div/>;
    }
}