import * as React from "react";
import {createElement, useCallback, useEffect, useMemo, useState} from "react";
import {TextField} from "@material-ui/core";

interface SimpleNumberInputProps {
    label: string;
    value?: number;
    onChange: (value?: number) => void;
    floatingPoint?: boolean;
    disabled?: boolean;
}

export function SimpleNumberInput(props: SimpleNumberInputProps) {
    const {label, value, onChange, floatingPoint, disabled} = props;

    const handleChange = useCallback((value: string) => {
        if(value) {
            let parsed: number;
            try {
                if(floatingPoint) {
                    parsed = parseFloat(value);
                }else {
                    parsed = parseInt(value);
                }
                onChange(parsed);
            }catch(e) { /* ignore erroneous input */ }
        }else {
            onChange(undefined);
        }
    }, [onChange, floatingPoint]);

    return (
        <TextField variant="outlined" fullWidth label={label} disabled={disabled} type="number"
                   value={value != undefined ? value : ''}
                   onChange={(e) => handleChange(e.target.value)} />
    );
}
