import {Button, ButtonGroup, ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {createElement, useRef, useState} from "react";
import {ArrowDropDownIcon} from "../../icons";
import {messages} from "../../i18n";

type CubeBoxActionsComponentProps = {
    disabledBox: boolean;
    onOpenBox: () => void;
    onEnableBox: () => void;
    onDisableBox: () => void;
};

export const CubeBoxActionsComponent = (props: CubeBoxActionsComponentProps) => {
    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    let anchorRef = useRef<HTMLDivElement>(null);

    const togglePopper = () => {
        setPopperOpen(!popperOpen);
    };

    return (
        <ButtonGroup variant="outlined" ref={anchorRef}>
            <Button onClick={props.onOpenBox}>{messages.cubes.boxes.openBox}</Button>
            <Button onClick={togglePopper}><ArrowDropDownIcon/></Button>
            { anchorRef && (
                <Popper open={popperOpen} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 999 }}>
                    <Paper>
                        <ClickAwayListener onClickAway={togglePopper}>
                            <MenuList>
                                {props.disabledBox ?
                                    <MenuItem key="enableBox" onClick={props.onEnableBox}>{messages.cubes.boxes.enableBox}</MenuItem> :
                                    <MenuItem key="disableBox" onClick={props.onDisableBox}>{messages.cubes.boxes.disableBox}</MenuItem>
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            )}
        </ButtonGroup>
    );
};