import {Api} from "../Api";
import {Page, Pageable, User, UserCreateRequest} from "./types";
import {paging} from "./paging";

export class UsersProvider {

    static list(tenantId: string, pageable: Pageable) {
        return Api.GET<Page<User>>(paging.attachToUrl(pageable,`tenants/${tenantId}/users`));
    }

    static create(tenantId: string, request: UserCreateRequest) {
        return Api.POST<User>(`tenants/${tenantId}/users`, request);
    }

    static me(tenantId: string) {
        return Api.GET<User>(`tenants/${tenantId}/users/current-user`);
    }

    static get(tenantId: string, uuid: string) {
        return Api.GET<User>(`tenants/${tenantId}/users/${uuid}`);
    }

    static delete(tenantId: string, uuid: string) {
        return Api.DELETE<void>(`tenants/${tenantId}/users/${uuid}`);
    }
}
