import {Api} from "../Api";
import {Statistics} from "./types";

export class StatisticsProvider {

    static compute(tenantId: string) {
        return Api.GET<Statistics>(`tenants/${tenantId}/statistics`);
    }

}
