import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {LabeledData} from "../LabeledData";
import {Grid, makeStyles} from "@material-ui/core";
import {maxLen} from "../../tools";
import {Cube} from "../../domain/types";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";
import {ApiError} from "../../Api";
import {CubeDescriptionDisplayComponent} from "./CubeDescriptionDisplayComponent";

interface CubeBoxLinkComponentProps {
    cubeId: string;
    boxNumber: string;
    short?: boolean;
}

export function CubeBoxLinkComponent({cubeId, boxNumber, short}: CubeBoxLinkComponentProps) {
    const [cube, setCube] = useState<Cube>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const history = useHistory();
    const cubeLabel = short ? maxLen(cubeId, 25) : cubeId;

    // TODO useMemo(), caching
    useEffect( () => {
        CubesProvider.get(userStore.getTenantId(), cubeId)
            .then(cube => {
                setCube(cube);
            })
            .catch(toggleError);
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <Link style={{cursor: 'pointer'}} to={`/cubes/${cubeId}/boxes/${boxNumber}`} >
            {cube &&
                <Grid container>
                    <Grid item>
                        <CubeDescriptionDisplayComponent cube={cube} displayShortDescription={true}/>
                    </Grid>
                    <Grid item>
                        &nbsp;/ {boxNumber}
                    </Grid>
                </Grid>
            }
            {!cube &&
                <div>
                    {cubeLabel} / {boxNumber}
                </div>
            }
        </Link>
    );
}

const useStyles = makeStyles({
    noWrap: {
        whiteSpace: 'nowrap'
    }
});
