import * as React from "react";
import {createElement, useCallback} from "react";
import {CallbackMethod} from "../../domain/types";
import {MenuItem, TextField} from "@material-ui/core";

interface CallbackMethodInputProps {
    label: string;
    value: CallbackMethod;
    onChange: (value: CallbackMethod) => void;
}

export function CallbackMethodInput(props: CallbackMethodInputProps) {
    const {label, value, onChange} = props;

    const handleChange = useCallback((value: string) => {
        onChange(value as CallbackMethod);
    }, []);

    return (
        <TextField variant="outlined" fullWidth select label={label}
                   value={value}
                   onChange={(e) => handleChange(e.target.value)}>
            <MenuItem key={CallbackMethod.POST} value={CallbackMethod.POST}>POST</MenuItem>
            <MenuItem key={CallbackMethod.PUT} value={CallbackMethod.PUT}>PUT</MenuItem>
        </TextField>
    );
}
