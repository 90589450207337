import {Delivery, Page} from "../../domain/types";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {createElement, useState} from "react";
import {messages} from "../../i18n";
import {NotesIcon} from "../../icons";
import {DeliveryStateComponent} from "./DeliveryStateComponent";
import {CubeBoxLinkComponent} from "../cubes/CubeBoxLinkComponent";
import {DateComponent} from "../DateComponent";
import {makeStyles} from "@material-ui/core/styles";
import {EmptyDeliveriesTable} from "./EmptyDeliveriesTable";

type Column = 'cubeBox' | 'delivery' | 'created' | 'stored' | 'pickedUp';

export const availableColumns = ['cubeBox', 'delivery', 'created', 'stored', 'pickedUp'];
export const defaultColumns = ['cubeBox', 'delivery', 'created', 'stored', 'pickedUp'];

type sortKey = 'state' | 'carrierId' | 'service' | 'cubeId' | 'deliveryId' | 'created' | 'stored';

interface DeliveriesTableProps {
    deliveries: Page<Delivery>;
    columns?: Column[];
    onOpen: (delivery: Delivery) => void;
    selectedDeliveries?: Delivery[];
    onSelectionChanged?: (selectedDeliveries: Delivery[]) => void;
    onSortChange: (sort: string, direction: 'asc' | 'desc') => void;
	onResetFilter: () => void;
}

export const DeliveriesTable = (props: DeliveriesTableProps) => {
    const classes = useStyles();
    const {deliveries, onOpen, selectedDeliveries, onSelectionChanged, onResetFilter} = props;
    const columns = props.columns || defaultColumns;

    const allowSelection = onSelectionChanged != undefined && selectedDeliveries != undefined;

    const showColumn = (column: Column) => {
        return columns.indexOf(column) != -1;
    }

    const deliverySelected = (delivery: Delivery) => {
        if (selectedDeliveries) {
            return selectedDeliveries
                .map(d => d.id)
                .indexOf(delivery.id) != -1;
        }
        return false;
    }

    const selectDelivery = (delivery: Delivery, checked: boolean) => {
        if (selectedDeliveries && onSelectionChanged) {
            if (checked) {
                onSelectionChanged([ ...selectedDeliveries, delivery ]);
            } else {
                onSelectionChanged(selectedDeliveries.filter(d => d.id != delivery.id));
            }
        }
    }

    const colspan = 3 + columns.length;

    const [orderBy, setOrderBy] = useState<sortKey>('created');
    const [orderAsc, setOrderAsc] = useState<boolean>(false);
    const onSort = (key: sortKey) => {
        const {onSortChange} = props;
        if (orderBy === key) {
            const isAsc = !orderAsc;
            setOrderAsc(isAsc);
            if (onSortChange) {
                onSortChange(key, (isAsc) ? 'asc' : 'desc');
            }
        } else {
            setOrderBy(key);
            if (onSortChange) {
                onSortChange(key, orderAsc ? 'asc' : 'desc');
            }
        }
    }

    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    {allowSelection && (
                        <TableCell padding="checkbox">
                            TABLE SELECT ALL
                        </TableCell>
                    )}
                    <TableCell>
                        <TableSortLabel active={orderBy === 'state'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('state')}>
                            {messages.deliveries.state}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'carrierId'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('carrierId')}>
                            {messages.deliveries.carrier}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderBy === 'service'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('service')}>
                            {messages.deliveries.service}
                        </TableSortLabel>
                    </TableCell>
                    {showColumn('cubeBox') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'cubeId'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('cubeId')}>
                            {messages.deliveries.cubeBox}
                        </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('delivery') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'deliveryId'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('deliveryId')}>
                            {messages.deliveries.singular}
                        </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('created') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'created'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('created')}>
                            {messages.deliveries.created}
                        </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('stored') && (
                        <TableCell>
                            <TableSortLabel active={orderBy === 'stored'}
                                        direction={orderAsc ? 'asc' : 'desc'}
                                        onClick={() => onSort('stored')}>
                            {messages.deliveries.stored}
                        </TableSortLabel>
                        </TableCell>
                    )}
                    {showColumn('pickedUp') && <TableCell>{messages.deliveries.pickedUp}</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {deliveries.content.map(d => (
                    <TableRow key={d.id} hover onClick={() => onOpen(d)} className={classes.clickableRow}>
                        {allowSelection && (
                            <TableCell padding="checkbox" onClick={e => {e.stopPropagation(); e.preventDefault()}}>
                                <Checkbox checked={deliverySelected(d)} onChange={e => selectDelivery(d, e.target.checked)}/>
                            </TableCell>
                        )}
                        <TableCell><DeliveryStateComponent state={d.state} size="small" icon={d.notes ? <NotesIcon /> : undefined} /></TableCell>
                        <TableCell>{d.carrierId}</TableCell>
                        <TableCell>{messages.deliveries.serviceEnum[d.service]}</TableCell>
                        {showColumn('cubeBox') && <TableCell><CubeBoxLinkComponent cubeId={d.cubeId} boxNumber={d.boxNumber} short /></TableCell>}
                        {showColumn('delivery') && <TableCell>{d.deliveryId}</TableCell>}
                        {showColumn('created') && <TableCell><DateComponent date={d.created} /></TableCell>}
                        {showColumn('stored') && <TableCell>{d.stored && <DateComponent date={d.stored} />}</TableCell>}
                        {showColumn('pickedUp') && (
                            <TableCell>
                                {d.pickedUp && <DateComponent date={d.pickedUp} />}
                                {d.retrieved && <DateComponent date={d.retrieved} />}
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {deliveries.totalElements == 0 && (
                    <EmptyDeliveriesTable colSpan={colspan} onResetFilter={onResetFilter} />
                )}
            </TableBody>
        </Table>
    )
};

const useStyles = makeStyles((t) => ({
    clickableRow: {
        cursor: 'pointer'
    }
}));
