import * as React from "react";
import {createElement} from "react";
import {FirstMileField} from "../../domain/types";
import {Chip, Grid} from "@material-ui/core";
import {messages} from "../../i18n";

interface FirstMileFieldDisplayProps {
    field: FirstMileField;
}

export function FirstMileFieldDisplay(props: FirstMileFieldDisplayProps) {
    const {field} = props;
    return (
        <Chip label={messages.carriers.firstMileConfiguration.firstMileFields[field]} />
    );
}

interface FirstMileFieldsDisplayProps {
    fields: FirstMileField[];
}

export function FirstMileFieldsDisplay(props: FirstMileFieldsDisplayProps) {
    const {fields} = props;
    return (
        <Grid container spacing={1}>
            {fields.map((f) =>
            <Grid key={f} item>
                <FirstMileFieldDisplay field={f} />
            </Grid>
            )}
        </Grid>
    );
}
