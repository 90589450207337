import * as React from "react";
import {createElement, useCallback} from "react";
import {SignatureOutputFormat} from "../../domain/types";
import {MenuItem, TextField} from "@material-ui/core";
import {messages} from "../../i18n";

interface SignatureOutputFormatInput {
    label: string;
    value: SignatureOutputFormat;
    onChange: (value: SignatureOutputFormat) => void;
}

export function SignatureOutputFormatInput(props: SignatureOutputFormatInput) {
    const {label, value, onChange} = props;

    const handleChange = useCallback((value: string) => {
        onChange(value as SignatureOutputFormat);
    }, []);

    return (
        <TextField variant="outlined" fullWidth select label={label}
                   value={value}
                   onChange={(e) => handleChange(e.target.value)}>
            <MenuItem key={SignatureOutputFormat.Jpg} value={SignatureOutputFormat.Jpg}>{messages.carriers.signatureConfiguration.outputFormats.Jpg}</MenuItem>
            <MenuItem key={SignatureOutputFormat.Png} value={SignatureOutputFormat.Png}>{messages.carriers.signatureConfiguration.outputFormats.Png}</MenuItem>
            <MenuItem key={SignatureOutputFormat.PngNonAlpha} value={SignatureOutputFormat.PngNonAlpha}>{messages.carriers.signatureConfiguration.outputFormats.PngNonAlpha}</MenuItem>
            <MenuItem key={SignatureOutputFormat.JsonPointList} value={SignatureOutputFormat.JsonPointList}>{messages.carriers.signatureConfiguration.outputFormats.JsonPointList}</MenuItem>
        </TextField>
    );
}
