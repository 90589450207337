import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {createElement} from "react";
import {messages} from "../i18n";
import {ErrorIcon} from "../icons";
import {red} from "@material-ui/core/colors";

type ErrorPopupProps = {
    title?: string;
    errorMessage?: string;
    url?: string;
    statusCode?: number;
    onCloseHandler: () => void;
};

export const ErrorPopup = ({title, errorMessage, url, statusCode, onCloseHandler}: ErrorPopupProps) => {
    const errorStyles = {
        color: red[400],
        position: 'relative',
        top: '0.3em',
        marginRight: 10
    };
    return (
        <Dialog open={true} onClose={onCloseHandler}>
            <DialogTitle><ErrorIcon fontSize="large" style={errorStyles as any}/> {title || messages.errorDialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {errorMessage || messages.errorDialogMessage}
                </DialogContentText>
                {url && (
                    <Box my={1}>
                        <Typography variant="overline">URL</Typography>
                        <Typography variant="subtitle1" style={{ marginBottom: 0 }}><strong>{url}</strong></Typography>
                    </Box>
                )}
                {statusCode && (
                    <Box my={1}>
                        <Typography variant="overline">Error Status Code</Typography>
                        <Typography variant="h4" style={{ marginBottom: 0 }}>{statusCode}</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseHandler}>{messages.close}</Button>
            </DialogActions>
        </Dialog>
    )
};
