import * as React from "react";
import {createElement} from "react";
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";

export interface SimpleDonutChartData {
    series: number[];
    labels: string[];
}

interface SimpleDonutChartProps {
    data: SimpleDonutChartData;
}

export function SimpleDonutChart(props: SimpleDonutChartProps) {
    const {series, labels} = props.data;
    const options: ApexOptions = {
        labels: labels,
        legend: {
            position: 'bottom'
        }
    };

    return (<Chart options={options} series={series} type="donut" />);
}
