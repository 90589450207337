import * as React from "react";
import {createElement, useCallback, useState} from "react";
import {Cube} from "../../domain/types";
import {gs} from "../../theme";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {messages} from "../../i18n";
import {LabeledData} from "../LabeledData";
import {SettingsIcon} from "../../icons";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";
import {CarrierSelectInput} from "../base/CarrierSelectInput";

interface CubeAccessCodesComponentProps {
    cube: Cube;
    onChanged: () => void;
}

export function CubeAccessCodesComponent({cube, onChanged}: CubeAccessCodesComponentProps) {
    const {accessCodes} = cube;

    const [updateCarrierAccessCode, setUpdateCarrierAccessCode] = useState<string>();
    const [fixedAccessCode, setFixedAccessCode] = useState<string>('');
    const [pin, setPin] = useState<string>('');

    const handleUpdateCode = useCallback(async () => {
        if (updateCarrierAccessCode) {
            await CubesProvider.setCarrierAccessCode(userStore.getTenantId(), cube.cubeId, updateCarrierAccessCode, fixedAccessCode, pin)
            setUpdateCarrierAccessCode(undefined);
            onChanged();
        }
    }, [updateCarrierAccessCode, fixedAccessCode, pin, onChanged]);

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Typography variant="subtitle1"><strong>{messages.cubes.accessCodes.title}</strong></Typography>
            </Grid>
            <Grid item xs>
                <LabeledData label={messages.cubes.accessCodes.readOnly}>
                    <Typography variant="body1">{accessCodes.readOnly}</Typography>
                </LabeledData>
            </Grid>
            <Grid item xs>
                <LabeledData label={messages.cubes.accessCodes.nonOccupied}>
                    <Typography variant="body1">{accessCodes.openNonOccupied}</Typography>
                </LabeledData>
            </Grid>
            <Grid item xs>
                <LabeledData label={messages.cubes.accessCodes.fullAccess}>
                    <Typography variant="body1">{accessCodes.fullAccess}</Typography>
                </LabeledData>
            </Grid>
            {Object.entries(accessCodes.carrier ?? {}).map(([carrierId, codes]) => (
                <Grid item xs={12} key={carrierId}>
                    <Grid container spacing={gs}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="subtitle1"><strong>{carrierId}</strong></Typography>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => setUpdateCarrierAccessCode(carrierId)}><SettingsIcon /></IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={gs}>
                        <Grid item>
                            <LabeledData label={messages.cubes.accessCodes.accessCode}>
                                {codes.accessCode}
                            </LabeledData>
                        </Grid>
                        <Grid item>
                            <LabeledData label={messages.cubes.accessCodes.fixedAccessCode}>
                                {codes.fixedAccessCode}
                            </LabeledData>
                        </Grid>
                        <Grid item>
                            <LabeledData label={messages.cubes.accessCodes.pin}>
                                {codes.pin}
                            </LabeledData>
                        </Grid>
                    </Grid>
                </Grid>

            ))}

            <Dialog open={Boolean(updateCarrierAccessCode)} onClose={() => setUpdateCarrierAccessCode(undefined)} maxWidth="md">
                <DialogTitle>{messages.cubes.accessCodes.carrierAccessCode.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <CarrierSelectInput label={messages.cubes.accessCodes.carrierAccessCode.carrierId} carrierId={updateCarrierAccessCode} onChange={setUpdateCarrierAccessCode} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                       value={fixedAccessCode} onChange={(e) => setFixedAccessCode(e.target.value)}
                                       label={messages.cubes.accessCodes.carrierAccessCode.fixedAccessCode} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth
                                       value={pin} onChange={(e) => setPin(e.target.value)}
                                       label={messages.cubes.accessCodes.carrierAccessCode.pin} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUpdateCarrierAccessCode(undefined)}>{messages.cancel}</Button>
                    <Button color="primary" disabled={fixedAccessCode.length == 0} onClick={() => handleUpdateCode()}>{messages.save}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
