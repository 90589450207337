import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {messages} from "../../i18n";
import {Carrier} from "../../domain/types";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {userStore} from "../../domain/UserStore";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";

interface CarrierSelectInputProps {
    label: string;
    carrierId?: string;
    onChange: (carrierId: string) => void;
}

export function CarrierSelectInput(props: CarrierSelectInputProps) {
    const {label, carrierId, onChange} = props;
    const [processing, setProcessing] = useState<boolean>(false);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect( () => {
        setProcessing(true);
        CarrierProvider.list(userStore.getTenantId())
            .then(p => {
                setCarriers(p.content);
                setProcessing(false);
            })
            .catch(toggleError)
    },[setCarriers]);

    const toggleError = useCallback((error?: ApiError) => {
        setError(error);
        setProcessing(false);
    }, []);

    const handleChange = useCallback((event: any) => {
        if(event.target.value) {
            onChange(event.target.value as string);
        }
    }, [onChange]);

    return (
        <FormControl variant="outlined" fullWidth required disabled={processing}>
            <InputLabel>{label}</InputLabel>
            <Select name="carrierId"
                    value={carrierId || ''}
                    onChange={(e) => handleChange(e)}
                    label={label}
            >
                <MenuItem value={''}>{messages.deliveries.create.selectCarrier}</MenuItem>
                {carriers.map(c => <MenuItem key={c.id} value={c.id}>{c.name || c.id}</MenuItem>)}
            </Select>
            {error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </FormControl>
    );
}
