import {Component, createElement} from "react";
import {Hidden} from "@material-ui/core";
import React = require("react");

export class VCLogo extends Component<{ }> {

    render() {
        return(
            <React.Fragment>
                <Hidden smDown>
                    <img style={{ height: '3.5em', padding: '-12px' }} src="/VC_LOGO_RGB_Main.svg" />
                </Hidden>
                <Hidden mdUp>
                    <img style={{ height: '3.5em', padding: '-12px' }} src="/VC_SIGNE_RGB_Main.svg" />
                </Hidden>
            </React.Fragment>
        );
    }

}
