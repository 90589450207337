import * as React from "react";
import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {CheckCircle, Block} from "@material-ui/icons";
import {messages} from "../../i18n";

interface CubeBoxAvailableComponentProps {
    available: boolean;
}

export function CubeBoxAvailableComponent(props: CubeBoxAvailableComponentProps) {
    const {available} = props;
    const icon = available ? <CheckCircle /> : <Block />;
    const label = available ? messages.cubes.boxes.enabled : messages.cubes.boxes.disabled;
    const color = available ? '#4caf50' : '#f44336';
    return (
        <Chip label={label} style={{color: '#ffffff', backgroundColor: color}} icon={icon} />
    );
}
