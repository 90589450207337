import * as React from "react";
import {createElement, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core";

interface IdDisplayProps {
    id: string;
}

export function IdDisplay({id}: IdDisplayProps) {
    const classes = useStyles();
    const [full, setFull] = useState<boolean>(false);

    const label = useMemo<string>(() => {
        if(full) {
            return id;
        }else {
            return id.slice(0, 4) + '..' + id.slice(id.length-5, id.length-1);
        }
    }, [id, full]);

    return (<span className={classes.id} onClick={() => setFull(!full)}>{label}</span>);
}

const useStyles = makeStyles(theme => ({
    id: {
        fontFamily: 'monospace',
        cursor: 'pointer',
        fontSize: '0.9em'
    }
}));
