import {RouteComponentProps, useHistory} from "react-router";
import React, {createElement, useCallback, useEffect, useState} from "react";
import {
	ApiCredentials,
	CallbackMethod,
	Carrier,
	CarrierAuthType,
	CarrierUpdateRequest,
	FirstMileConfiguration,
	FirstMileField,
	LastMileConfiguration,
	MessageDialogConfiguration,
	SignatureConfiguration,
	SignatureOutputFormat
} from "../../domain/types";
import {Api, ApiError} from "../../Api";
import {userStore} from "../../domain/UserStore";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Typography} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {ErrorPopup} from "../ErrorPopup";
import {gs} from "../../theme";
import {BoxedPaper} from "../base/BoxedPaper";
import {SimpleTextInput} from "../base/SimpleTextInput";
import {AuthTypeInput} from "../base/AuthTypeInput";
import {CallbackMethodInput} from "../base/CallbackMethodInput";
import {SignatureOutputFormatInput} from "../base/SignatureOutputFormatInput";
import {SimpleNumberInput} from "../base/SimpleNumberInput";
import {SimpleCheckBox} from "../SimpleCheckBox";

export const CarrierEdit = (props: RouteComponentProps<{ carrierId: string }>) => {
    const history = useHistory();
    const [carrier, setCarrier] = useState<Carrier|undefined>(undefined);

    const [name, setName] = useState<string>('');
    const [dataRetentionPolicyDays, setDataRetentionPolicyDays] = useState<string>('');
    const [maxStorageHours, setMaxStorageHours] = useState<string>('');
    const [callbackMethod, setCallbackMethod] = useState<CallbackMethod>(CallbackMethod.POST);
    const [callbackUrl, setCallbackUrl] = useState<string>('');
    const [authType, setAuthType] = useState<CarrierAuthType>(CarrierAuthType.Bearer);
    const [token, setToken] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [signatureOutputFormat, setSignatureOutputFormat] = useState<SignatureOutputFormat>(SignatureOutputFormat.Jpg);
    const [signatureWidth, setSignatureWidth] = useState<number | undefined>(0);
    const [signatureHeight, setSignatureHeight] = useState<number | undefined>(0);
    const [strokeMinWidth, setStrokeMinWidth] = useState<number | undefined>(0);
    const [strokeMaxWidth, setStrokeMaxWidth] = useState<number | undefined>(0);
    const [availableFields, setAvailableFields] = useState<FirstMileField[]>([]);
    const [requiredFields, setRequiredFields] = useState<FirstMileField[]>([]);
    const [firstMileReopenCountdown, setFirstMileReopenCountdown] = useState<number | undefined>();
    const [firstMileOfferReceipt, setFirstMileOfferReceipt] = useState<boolean>(false);
    const [allContactFieldsRequired, setAllContactFieldsRequired] = useState<boolean>(false);
    const [verifyContactData, setVerifyContactData] = useState<boolean>(false);
    const [recipientNameRequired, setRecipientNameRequired] = useState<boolean>(false);
    const [carrierInterfaceShowsDeliveryOption, setCarrierInterfaceShowsDeliveryOption] = useState<boolean>(false);
    const [lastMileReopenCountdown, setLastMileReopenCountdown] = useState<number | undefined>();
    const [baseUrl, setBaseUrl] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');
    const [appName, setAppName] = useState<string>('');
    const [appCode, setAppCode] = useState<string>('');
    const [appVersion, setAppVersion] = useState<string>('1.0.0');
    const [publicKey, setPublicKey] = useState<string>('');
    const [messageDialogHideCloseButton, setMessageDialogHideCloseButton] = useState<boolean>(false);
    const [messageDialogAutoCloseTimer, setMessageDialogAutoCloseTimer] = useState<number | undefined>();
    const [autoCarrierRetrieval, setAutoCarrierRetrieval] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<ApiError | undefined>(undefined);

    useEffect(() => {
        CarrierProvider.get(userStore.getTenantId(), props.match.params.carrierId)
            .then(c => {
                setCarrier(c);
                setName(c.name);
                setDataRetentionPolicyDays('' + c.dataRetentionPolicyDays);
                setMaxStorageHours('' + c.maxStorageHours);
                setCallbackUrl(c.callbackUrl);
                setCallbackMethod(c.callbackMethod);
                setAuthType(c.apiCredentials?.authType || CarrierAuthType.Bearer);
                setToken(c.apiCredentials?.token || "");
                setUsername(c.apiCredentials?.username || "");
                setPassword(c.apiCredentials?.password || "");
                setSignatureOutputFormat(c.signatureConfiguration.outputFormat);
                setSignatureWidth(c.signatureConfiguration.width);
                setSignatureHeight(c.signatureConfiguration.height);
                setStrokeMinWidth(c.signatureConfiguration.strokeMinWidth);
                setStrokeMaxWidth(c.signatureConfiguration.strokeMaxWidth);
                setAvailableFields(c.firstMileConfiguration.availableFields);
                setRequiredFields(c.firstMileConfiguration.requiredFields);
                setAllContactFieldsRequired(c.firstMileConfiguration.allContactFieldsRequired);
                setVerifyContactData(c.firstMileConfiguration.verifyContactData);
                setRecipientNameRequired(!!c.lastMileConfiguration?.recipientNameRequired);
                setBaseUrl(c.apiCredentials?.baseUrl || "");
                setAppName(c.appName || "");
                setAppCode(c.appCode || "");
                setClientId(c.apiCredentials?.username || "");
                setClientSecret(c.apiCredentials?.password || "");
                setPublicKey(c.publicKey || "");
                setMessageDialogHideCloseButton(c.messageDialogConfiguration?.hideCloseButton || false);
                setMessageDialogAutoCloseTimer(c.messageDialogConfiguration?.autoCloseTimer || 15000);
                setCarrierInterfaceShowsDeliveryOption(!!c.lastMileConfiguration?.carrierInterfaceShowsDeliveryOption);
                setLastMileReopenCountdown(c.lastMileConfiguration?.reopenCountdown || 20);
                setFirstMileReopenCountdown(c.firstMileConfiguration.reopenCountdown || 20);
                setFirstMileOfferReceipt(!!c.firstMileConfiguration.offerReceipt);
                setAutoCarrierRetrieval(!!c.autoCarrierRetrieval);
            })
            .catch(toggleError)
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSave = useCallback(() => {
        if(carrier) {
            setSubmitting(true);
            try {
                const signatureConfiguration: SignatureConfiguration = {
                    outputFormat: signatureOutputFormat,
                    width: signatureWidth || 300,
                    height: signatureHeight || 125,
                    strokeMinWidth: strokeMinWidth || 0.5,
                    strokeMaxWidth: strokeMaxWidth || 2.5
                };
                const firstMileConfiguration: FirstMileConfiguration = {
                    availableFields: availableFields,
                    requiredFields: requiredFields,
                    allContactFieldsRequired: allContactFieldsRequired,
                    verifyContactData: verifyContactData,
                    offerReceipt: firstMileOfferReceipt,
                    reopenCountdown: firstMileReopenCountdown
                };
                const lastMileConfiguration: LastMileConfiguration = {
                    recipientNameRequired,
                    carrierInterfaceShowsDeliveryOption,
                    reopenCountdown: lastMileReopenCountdown,
                };
                const messageDialogConfiguration: MessageDialogConfiguration = {
                    autoCloseTimer: messageDialogAutoCloseTimer,
                    hideCloseButton: messageDialogHideCloseButton,
                };
				let apiCredentials: ApiCredentials = {
					baseUrl, authType
				};
				switch (authType) {
					case CarrierAuthType.Bearer:
					case CarrierAuthType.Raw:
						apiCredentials.username = clientId;
						apiCredentials.password = clientSecret;
						apiCredentials.token = token;
						break;
					case CarrierAuthType.Basic:
						apiCredentials.username = username;
						apiCredentials.password = password;
						break;
					case CarrierAuthType.None:
						break;
				}
                const updateRequest: CarrierUpdateRequest = {
                    name,
                    dataRetentionPolicyDays: parseInt(dataRetentionPolicyDays),
                    maxStorageHours: parseInt(maxStorageHours),
                    callbackMethod,
                    callbackUrl,
                    signatureConfiguration,
                    firstMileConfiguration,
                    lastMileConfiguration,
                    apiCredentials,
                    publicKey,
                    messageDialogConfiguration,
                    autoCarrierRetrieval,
                    appName,
                    appCode
                };
                CarrierProvider.update(userStore.getTenantId(), carrier.id, updateRequest)
                    .then(() => {
                        history.push(`/carriers/${carrier.id}`);
                    })
                    .catch((e) => toggleError(e))
                    .finally(() => setSubmitting(false));
            }catch (e) {
                setSubmitting(false);
            }
        }
    }, [carrier, name, dataRetentionPolicyDays, maxStorageHours,
        callbackMethod, callbackUrl, authType, token,
        username, password,
        signatureOutputFormat, signatureWidth, signatureHeight, strokeMinWidth, strokeMaxWidth,
        availableFields, requiredFields, allContactFieldsRequired, verifyContactData, recipientNameRequired,
        baseUrl, clientId, clientSecret, appName, appCode, publicKey, autoCarrierRetrieval, firstMileOfferReceipt, lastMileReopenCountdown, firstMileReopenCountdown, messageDialogAutoCloseTimer, messageDialogHideCloseButton,
    ]);

    return (
        <ContainerLayout>
            {!carrier && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {carrier && (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb route="/carriers" label={messages.carriers.plural} />
                                <Crumb route={`/carriers/${carrier.id}`} label={carrier.name || carrier.id} />
                                <Crumb label={messages.carriers.edit.title} />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" gutterBottom>
                                        {`${messages.carriers.edit.title}: ${carrier.name || carrier.id}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.general}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <SimpleTextInput label={messages.carriers.name} value={name} onChange={setName} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleTextInput label={messages.carriers.dataRetentionPolicy} value={dataRetentionPolicyDays} onChange={setDataRetentionPolicyDays} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SimpleTextInput label={messages.carriers.maxStorageHours} value={maxStorageHours} onChange={setMaxStorageHours} />
                                    </Grid>
                                    <Grid item>
                                        <SimpleCheckBox label={messages.carriers.autoCarrierRetrieval} checked={autoCarrierRetrieval} onChange={setAutoCarrierRetrieval} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.callback}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={3}>
                                        <CallbackMethodInput label={messages.carriers.callbackMethod} value={callbackMethod} onChange={setCallbackMethod} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <SimpleTextInput label={messages.carriers.callbackUrl} value={callbackUrl} onChange={setCallbackUrl} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <AuthTypeInput label={messages.carriers.authType} value={authType} onChange={setAuthType} />
                                    </Grid>
                                    {(authType == CarrierAuthType.Basic) &&
                                    <React.Fragment>
                                        <Grid item xs={4}>
                                            <SimpleTextInput label={messages.carriers.username} value={username} onChange={setUsername} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SimpleTextInput label={messages.carriers.password} value={password} onChange={setPassword} />
                                        </Grid>
                                    </React.Fragment>
                                    }
                                    {(authType != CarrierAuthType.Basic && authType != CarrierAuthType.None) &&
                                    <Grid item xs={8}>
                                        <SimpleTextInput label={messages.carriers.token} value={token} onChange={setToken} />
                                    </Grid>
                                    }
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.apiCredentials}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <SimpleTextInput label={messages.carriers.baseUrl} value={baseUrl}
                                                         onChange={setBaseUrl}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleTextInput label={messages.carriers.appName} value={appName}
                                                         onChange={setAppName}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleTextInput label={messages.carriers.appCode} value={appCode}
                                                         onChange={setAppCode}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleTextInput label={messages.carriers.appVersion} value={appVersion}
                                                         onChange={setAppVersion}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SimpleTextInput label={messages.carriers.clientId} value={clientId}
                                                         onChange={setClientId}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SimpleTextInput label={messages.carriers.clientSecret} value={clientSecret}
                                                         onChange={setClientSecret}/>
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.publicKey}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12}>
                                        <SimpleTextInput rows={4} label={messages.carriers.publicKey} value={publicKey} onChange={setPublicKey} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.signature}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={4}>
                                        <SignatureOutputFormatInput label={messages.carriers.signatureConfiguration.outputFormat} value={signatureOutputFormat} onChange={setSignatureOutputFormat} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.signatureConfiguration.width} value={signatureWidth} onChange={setSignatureWidth} disabled={signatureOutputFormat == SignatureOutputFormat.JsonPointList} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.signatureConfiguration.height} value={signatureHeight} onChange={setSignatureHeight} disabled={signatureOutputFormat == SignatureOutputFormat.JsonPointList} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.signatureConfiguration.strokeMinWidth} floatingPoint value={strokeMinWidth} onChange={setStrokeMinWidth} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.signatureConfiguration.strokeMaxWidth} floatingPoint value={strokeMaxWidth} onChange={setStrokeMaxWidth} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.firstMile}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="overline">{messages.carriers.firstMileConfiguration.availableFields}</Typography>
                                        <Grid container spacing={gs}>
                                            <FirstMileFieldsInput fields={availableFields} onChange={setAvailableFields} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="overline">{messages.carriers.firstMileConfiguration.requiredFields}</Typography>
                                        <Grid container spacing={gs}>
                                            <FirstMileFieldsInput fields={requiredFields} onChange={setRequiredFields} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <SimpleCheckBox label={messages.carriers.firstMileConfiguration.allContactFieldsRequired} checked={allContactFieldsRequired} onChange={setAllContactFieldsRequired} />
                                            </Grid>
                                            <Grid item>
                                                <SimpleCheckBox label={messages.carriers.firstMileConfiguration.verifyContactData} checked={verifyContactData} onChange={setVerifyContactData} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.reopenCountdown} value={firstMileReopenCountdown} onChange={setFirstMileReopenCountdown} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleCheckBox label={messages.carriers.firstMileConfiguration.offerReceipt} checked={firstMileOfferReceipt} onChange={setFirstMileOfferReceipt} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.lastMile}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item>
                                        <SimpleCheckBox label={messages.carriers.lastMileConfiguration.recipientNameRequired} checked={recipientNameRequired} onChange={setRecipientNameRequired} />
                                    </Grid>
                                    <Grid item>
                                        <SimpleCheckBox label={messages.carriers.lastMileConfiguration.carrierInterfaceShowsDeliveryOption} checked={carrierInterfaceShowsDeliveryOption} onChange={setCarrierInterfaceShowsDeliveryOption} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleNumberInput label={messages.carriers.reopenCountdown} value={lastMileReopenCountdown} onChange={setLastMileReopenCountdown} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h5">{messages.carriers.messageDialogConfiguration.messageDialogConfiguration}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BoxedPaper loading={submitting}>
                                <Grid container spacing={gs}>
                                    <Grid item>
                                        <SimpleCheckBox label={messages.carriers.messageDialogConfiguration.hideCloseButton} checked={messageDialogHideCloseButton} onChange={setMessageDialogHideCloseButton} />
                                    </Grid>
                                    <Grid item>
                                        <SimpleNumberInput label={messages.carriers.messageDialogConfiguration.autoCloseTimer} value={messageDialogAutoCloseTimer} onChange={setMessageDialogAutoCloseTimer} />
                                    </Grid>
                                </Grid>
                            </BoxedPaper>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item style={{flexGrow: 1}}></Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={() => history.push(`/carriers/${carrier.id}`)}>{messages.cancel}</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" disabled={submitting} onClick={handleSave}>{messages.save}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}

            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
}

interface FirstMileFieldsInputProps {
    fields: FirstMileField[];
    onChange: (fields: FirstMileField[]) => void;
}

function FirstMileFieldsInput(props: FirstMileFieldsInputProps) {
    const {fields, onChange} = props;

    const handleChange = useCallback((f: FirstMileField, checked: boolean) => {
        if(checked) {
            onChange([... fields, f]);
        }else {
            onChange(fields.filter((e) => e != f));
        }
    }, [fields, onChange]);

    return (
        <React.Fragment>
            {Object.keys(FirstMileField)
                .map((f) => f as FirstMileField)
                .map((f) =>
            <Grid key={f} item>
                <SimpleCheckBox label={messages.carriers.firstMileConfiguration.firstMileFields[f]} checked={fields.indexOf(f) != -1} onChange={(c) => handleChange(f, c)} />
            </Grid>
            )}
        </React.Fragment>
    );
}
