import {RouteComponentProps} from "react-router";
import {createElement, useEffect, useState} from "react";
import {User} from "../../domain/types";
import {UsersProvider} from "../../domain/UsersProvider";
import {ApiError} from "../../Api";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Grid, Paper} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {LabeledData} from "../LabeledData";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";

export const UserDetails = (props: RouteComponentProps<{ userId: string }>) => {
    const [user, setUser] = useState<User|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        UsersProvider.get(userStore.getTenantId(), props.match.params.userId)
            .then(setUser)
            .catch(toggleError);
    }, [setUser]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            {!user && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {user && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb route="/users" label={messages.users.plural} />
                                <Crumb label={user.email} />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <LabeledData label={messages.users.email}>
                                                {user.email}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item>
                                            <LabeledData label={messages.users.role}>
                                                {user.role}
                                            </LabeledData>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <LabeledData label={messages.users.id}>
                                                {user.username}
                                            </LabeledData>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        {/*{(user.role == UserRole.ApiUser && user.apiToken ) &&*/}
                        {/*<Grid item xs={12}>*/}
                        {/*    <Paper>*/}
                        {/*        <Box p={2}>*/}
                        {/*            <LabeledData label={messages.users.apiToken}>*/}
                        {/*                <ApiTokenDisplayComponent token={user.apiToken} />*/}
                        {/*            </LabeledData>*/}
                        {/*        </Box>*/}
                        {/*    </Paper>*/}
                        {/*</Grid>*/}
                        {/*}*/}
                    </Grid>
                </div>
            )}
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
};
