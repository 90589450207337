import {Api} from "../Api";
import {Delivery, DeliveryCreateRequest, DeliveryFilter, Page, Pageable, QueueItem} from "./types";
import {luxonDate, luxonDateOrUndefined} from "../tools";
import {paging} from "./paging";
import {DateTime} from "luxon";

export class DeliveriesProvider {

    static attachFilter(originalPath: string, filter: DeliveryFilter): string {
        const separator = originalPath.indexOf('?') != -1 ? '&' : '?';
        let path = originalPath + separator;
        if(filter.fulltext) {
            path += `fulltext=${filter.fulltext}&`;
        }
        if (filter.from) {
            path += `from=${filter.from.toJSDate().toISOString()}&`;
        }
        if (filter.until) {
            path += `until=${filter.until.toJSDate().toISOString()}&`;
        }
        if (filter.states.length > 0) {
            path += filter.states.map(s => `states=${s}`).join('&') + '&';
        }
        if (filter.carriers.length > 0) {
            path += filter.carriers.map(s => `carriers=${s}`).join('&') + '&';
        }
        if (filter.services.length > 0) {
            path += filter.services.map(s => `services=${s}`).join('&') + '&';
        }
        if (filter.cubes.length > 0) {
            path += filter.cubes.map(s => `cubes=${s}`).join('&') + '&';
        }
        return path.slice(0, -1);
    }

    static list(tenantId: string, filter: DeliveryFilter, pageable: Pageable) {
        const path = this.attachFilter(paging.attachToUrl(pageable, `tenants/${tenantId}/deliveries`), filter);
        return Api.GET<Page<Delivery>>(path).then((pageResponse) => {
            return {
                ... pageResponse,
                content: pageResponse.content.map((d) => this.mapResponse(d))
            };
        });
    }

    static create(tenantId: string, request: DeliveryCreateRequest) {
        return Api.POST<Delivery>(`tenants/${tenantId}/deliveries`, request)
            .then((r) => this.mapResponse(r));
    }

    static get(tenantId: string, id: string) {
        return Api.GET<Delivery>(`tenants/${tenantId}/deliveries/${id}`)
            .then((r) => this.mapResponse(r));
    }

    static markMaxStorageDays(tenantId: string, id: string) {
        return Api.POST<void>(`tenants/${tenantId}/deliveries/${id}/mark-max-storage-days`, {});
    }

    static delete(tenantId: string, id: string) {
        return Api.DELETE<void>(`tenants/${tenantId}/deliveries/${id}`);
    }

    static getEvents(tenantId: string, id: string) {
        return Api.GET<QueueItem[]>(`tenants/${tenantId}/deliveries/${id}/events`);
    }

    static retryEvent(tenantId: string, deliveryUuid: string, eventUuid: string) {
        return Api.PUT<QueueItem>(`tenants/${tenantId}/deliveries/${deliveryUuid}/events/${eventUuid}`, {});
    }

    static saveNotes(tenantId: string, deliveryUuid: string, notes: string) {
        const request = { notes };
        return Api.PUT<Delivery>(`tenants/${tenantId}/deliveries/${deliveryUuid}/notes`, request)
            .then((r) => this.mapResponse(r));
    }

    static restoreDelivery(tenantId: string, deliverUuid: string, until: DateTime) {
        const request = {
            until: until.toJSDate().toISOString()
        };
        return Api.POST<Delivery>(`tenants/${tenantId}/deliveries/${deliverUuid}/restore`, request)
            .then((r) => this.mapResponse(r));
    }

    private static mapResponse(a: any) {
        return {
            ... a,
            created: luxonDate(a.created),
            ended: luxonDateOrUndefined(a.ended),
            reservationStart: luxonDateOrUndefined(a.reservationStart),
            reservationEnd: luxonDateOrUndefined(a.reservationEnd),
            stored: luxonDateOrUndefined(a.stored),
            pickedUp: luxonDateOrUndefined(a.pickedUp),
            retrieved: luxonDateOrUndefined(a.retrieved),
            cancelled: luxonDateOrUndefined(a.cancelled),
            notifiedMaxStorageDays: luxonDateOrUndefined(a.notifiedMaxStorageDays),
            notifiedCriticalStorageDays: luxonDateOrUndefined(a.notifiedCriticalStorageDays)
        };

    }

}

