import * as React from "react";
import {createElement, useCallback} from "react";
import {CarrierAuthType} from "../../domain/types";
import {MenuItem, TextField} from "@material-ui/core";

interface AuthTypeInputProps {
    label: string;
    value: CarrierAuthType;
    onChange: (value: CarrierAuthType) => void;
}

export function AuthTypeInput(props: AuthTypeInputProps) {
    const {label, value, onChange} = props;

    const handleChange = useCallback((value: string) => {
        onChange(value as CarrierAuthType);
    }, []);

    return (
        <TextField variant="outlined" fullWidth select label={label}
                   value={value}
                   onChange={(e) => handleChange(e.target.value)}>
            <MenuItem key={CarrierAuthType.Basic} value={CarrierAuthType.Basic}>Basic Authentication</MenuItem>
            <MenuItem key={CarrierAuthType.Bearer} value={CarrierAuthType.Bearer}>Bearer Token</MenuItem>
            <MenuItem key={CarrierAuthType.Raw} value={CarrierAuthType.Raw}>Raw Value</MenuItem>
            <MenuItem key={CarrierAuthType.None} value={CarrierAuthType.None}>No authentication</MenuItem>
        </TextField>
    );
}
