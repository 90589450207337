import {Tenant, User, UserRole} from "./types";
import {UsersProvider} from "./UsersProvider";

class UserStore {

    private static readonly USER_KEY = 'user';
    private static readonly TENANT_KEY = 'tenant';

    setTenant(tenant: Tenant) {
        localStorage.setItem(UserStore.TENANT_KEY, JSON.stringify(tenant));
    }

    setUser(user: User) {
        localStorage.setItem(UserStore.USER_KEY, JSON.stringify(user));
    }

    get tenant() {
        const data = localStorage.getItem(UserStore.TENANT_KEY);
        return data ? JSON.parse(data) as Tenant : undefined;
    }

    get user() {
        const data = localStorage.getItem(UserStore.USER_KEY);
        return data ? JSON.parse(data) as User : undefined;
    }

    get root() {
        return this.user != undefined && this.user.root;
    }

    get admin() {
        return this.hasRole(UserRole.Admin) || this.root;
    }

    hasRole(userRole: UserRole): boolean {
        if(this.user) {
            return this.user.role === userRole;
        }
        return false;
    }

    assertTenantIsSet(): Tenant {
        if(!this.tenant) {
            window.location.href = '/tenants';
            throw new Error('No tenant set in userTenantStore, redirecting to tenant selection.');
        }
        return this.tenant;
    }

    getTenantId() {
        return this.assertTenantIsSet().id;
    }

    load(): Promise<User> {
        this.assertTenantIsSet();
        return UsersProvider.me(userStore.getTenantId()).then((user) => {
            this.setUser(user)
            return user;
        });
    }
}

export const userStore = new UserStore();