import * as React from "react";
import {createElement} from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";

interface SimpleCheckBoxProps {
    checked: boolean;
    onChange: (value: boolean) => void;
    label: string;
}

export function SimpleCheckBox({checked, onChange, label}: SimpleCheckBoxProps) {
    return (
        <FormControlLabel control={
            <Checkbox color="primary"
                      checked={checked} onChange={(event) => onChange(event.target.checked)} />
        } label={label} />
    );
}
