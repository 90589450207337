import * as React from "react";
import {createElement} from "react";
import {Link, Typography} from "@material-ui/core";
import {useHistory} from "react-router";

interface CrumbProps {
    label: string;
    route?: string;
}

export function Crumb(props: CrumbProps) {
    const history = useHistory();
    const {label, route} = props;
    if(route) {
        return (<Link onClick={() => history.push(route)} style={{cursor: 'pointer'}}>{label}</Link>);
    }else {
        return (<Typography color="textPrimary">{label}</Typography>);
    }
}
