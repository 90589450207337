import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {Box, Button, Grid, TableCell, TableRow, Typography} from "@material-ui/core";
import {gs} from "../../theme";
import {BlockSvg} from "../BlockSvg";
import onTheWay from "../../assets/undraw_on_the_way_re_swjt.svg";
import {messages} from "../../i18n";
import {TenantsProvider} from "../../domain/TenantsProvider";
import {Alert} from "@material-ui/lab";
import {useHistory} from "react-router";

interface EmptyTableProps {
    colSpan: number;
	onResetFilter: () => void;
}

export function EmptyDeliveriesTable({colSpan, onResetFilter}: EmptyTableProps) {
	const history = useHistory();

	const [allowSwitchTenant, setAllowSwitchTenant] = useState(false);

	useEffect(() => {
		TenantsProvider.list().then(tenants => {
			setAllowSwitchTenant(tenants.length > 1);
		});
	}, []);

    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <Box p={6}>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Typography variant="h4" align="center">{messages.deliveries.none.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <BlockSvg src={onTheWay} large/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">{messages.deliveries.none.info}</Typography>
                        </Grid>
						<Grid item xs={12}>
							<Typography align="center">
								<Button variant="contained" onClick={() => onResetFilter()}>{messages.deliveries.none.resetFilter}</Button>
							</Typography>
						</Grid>
						{allowSwitchTenant &&
							<Grid item xs={12}>
								<Alert severity="info" action={
									<Button variant="contained" size="small" onClick={() => history.push("/tenants")}>{messages.footerBar.switchTenant}</Button>
								}>{messages.deliveries.none.switchTenant}</Alert>
							</Grid>
						}
                    </Grid>
                </Box>
            </TableCell>
        </TableRow>
    );
}
