import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {Box, LinearProgress, Paper} from "@material-ui/core";

interface BoxedPaperProps extends PropsWithChildren<{ p?: number, loading?: boolean }> {
}

export function BoxedPaper(props: BoxedPaperProps) {
    const p = props.p || 2;
    const {children, loading} = props;
    return (
        <Paper>
            <Box p={p}>{children}</Box>
            {loading && <LinearProgress variant="indeterminate" />}
        </Paper>
    );
}
