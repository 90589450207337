import {RouteComponentProps} from "react-router";
import {createElement, useEffect, useState} from "react";
import {Delivery, QueueItem} from "../../domain/types";
import {ContainerLayout} from "../ContainerLayout";
import {
    Box,
    Breadcrumbs, Button,
    Grid, Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper, TextField,
    Typography
} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {Loading} from "../Loading";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {LabeledData} from "../LabeledData";
import {DateComponent} from "../DateComponent";
import {AddIcon, CloseIcon, DoneIcon, SaveAltIcon} from "../../icons";
import {Alert, AlertTitle} from "@material-ui/lab";
import {ConfirmDialog} from "../ConfirmDialog";
import {ErrorPopup} from "../ErrorPopup";
import {ApiError} from "../../Api";
import {CubeBoxLinkComponent} from "../cubes/CubeBoxLinkComponent";
import {EventsTable} from "./EventsTable";
import {DeliveryStateComponent} from "./DeliveryStateComponent";
import {userStore} from "../../domain/UserStore";
import {gs} from "../../theme";
import React from 'react';
import {SignatureComponent} from "../SignatureComponent";
import {CarrierNotificationStateComponent} from "./CarrierNotificationStateComponent";
import {DeliveryActions} from "./DeliveryActions";
import {RestoreDeliveryDialog} from "./RestoreDeliveryDialog";
import {FormattedAlert} from "../FormattedAlert";
import {BoxedPaper} from "../base/BoxedPaper";
import {LabeledItem} from "../LabeledItem";

export const DeliveryDetails = (props: RouteComponentProps<{ deliveryId: string }>) => {
    const [delivery, setDelivery] = useState<Delivery|undefined>(undefined);
    const [notes, setNotes] = useState<string>('');
    const [events, setEvents] = useState<QueueItem[]|undefined>(undefined);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
    const [eventsError, setEventsError] = useState<ApiError|undefined>(undefined);
    const [deleteMode, setDeleteMode] = useState<boolean>(false);
    const [markMaxStorageDaysMode, setMarkMaxStorageDaysMode] = useState<boolean>(false);
    const [restoreDeliveryMode, setRestoreDeliveryMode] = useState<boolean>(false);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        fetchDelivery();
    }, [setDelivery, setError, setEvents]);

    const fetchDelivery = () => {
        const deliveryId = props.match.params.deliveryId;
        DeliveriesProvider.get(userStore.getTenantId(), deliveryId)
            .then((delivery) => {
                setDelivery(delivery);
                setNotes(delivery.notes || '')
            })
            .catch(toggleError);
        handleLoadEvents();
    };

    const handleLoadEvents = () => {
        const deliveryId = props.match.params.deliveryId;
        setLoadingEvents(true);
        DeliveriesProvider.getEvents(userStore.getTenantId(), deliveryId)
            .then(setEvents)
            .catch(error => setEventsError(error))
            .finally(() => setLoadingEvents(false));
    };

    const toggleMarkMaxStorageDays = () => {
        setMarkMaxStorageDaysMode(!markMaxStorageDaysMode);
    };

    const toggleRestoreDelivery = (reload?: boolean) => {
        setRestoreDeliveryMode(!restoreDeliveryMode);
        if(reload) {
            fetchDelivery();
        }
    };

    const toggleDelete = () => {
        setDeleteMode(!deleteMode);
    };

    const deleteDelivery = () => {
        if (delivery) {
            DeliveriesProvider.delete(userStore.getTenantId(), props.match.params.deliveryId)
                .then(() => props.history.replace("/deliveries"));
        }
    };

    const markMaxStorageDays = () => {
        if (delivery) {
            DeliveriesProvider.markMaxStorageDays(userStore.getTenantId(), props.match.params.deliveryId)
                .then(() => {
                    toggleMarkMaxStorageDays();
                    fetchDelivery();
                });
        }
    };

    const handleSaveNotes = () => {
        if(delivery) {
            DeliveriesProvider.saveNotes(userStore.getTenantId(), delivery.id, notes).then((d) => {
                setDelivery(d);
                setNotes(d.notes || '');
            });
        }
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            {!delivery && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {delivery && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb route={'/deliveries'} label={messages.deliveries.plural} />
                                <Crumb label={delivery.deliveryId} />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" gutterBottom>
                                        {`${messages.deliveries.singular}: ${delivery.deliveryId}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <DeliveryActions delivery={delivery}
                                                     onMarkMaxStorageDays={toggleMarkMaxStorageDays}
                                                     onRestoreDelivery={toggleRestoreDelivery}
                                                     onDeleteDelivery={toggleDelete}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.state}>
                                                        <DeliveryStateComponent state={delivery.state} size="small" />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.service}>
                                                        {messages.deliveries.serviceEnum[delivery.service]}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.carrier}>
                                                        {delivery.carrierId}
                                                    </LabeledData>
                                                </Grid>
                                                {(delivery.boxNumber != delivery.reservedBoxNumber) &&
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.boxUpgrade}>
                                                        <Typography variant="body1">{delivery.reservedBoxNumber}</Typography>
                                                    </LabeledData>
                                                </Grid>
                                                }
                                                <Grid item style={{flexGrow: 1}}>
                                                    <LabeledData label={messages.deliveries.cubeBox}>
                                                        <CubeBoxLinkComponent cubeId={delivery.cubeId} boxNumber={delivery.boxNumber} />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.carrierNotifications}>
                                                        <CarrierNotificationStateComponent disabledCarrierNotification={delivery.disabledCarrierNotification} size="small"/>
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.created}>
                                                        <DateComponent date={delivery.created} />
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.deliveries.ended}>
                                                        {delivery.ended ? <DateComponent date={delivery.ended} /> : '--'}
                                                    </LabeledData>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.accessCodes}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Box p={3}>
                                                    <Grid container spacing={1}>
                                                        {delivery.recipientPickupCode &&
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.recipientPickupCode}>
                                                                <Typography variant="h5">{delivery.recipientPickupCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {delivery.carrierDeliveryCode &&
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.carrierDeliveryCode}>
                                                                <Typography variant="h5">{delivery.carrierDeliveryCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <LabeledData label={messages.deliveries.carrierRetrievalCode}>
                                                                <Typography variant="h5">{delivery.carrierRetrievalCode}</Typography>
                                                            </LabeledData>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        {delivery.signature &&
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{messages.deliveries.signature}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper>
                                                    <Box p={2}>
                                                        <SignatureComponent signature={delivery.signature} isPicture={delivery.carrierId !== "ups"} />
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                        {(delivery.senderName && delivery.senderEmail && delivery.senderPhone) &&
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{messages.deliveries.sender.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <BoxedPaper>
                                                    <Grid container spacing={gs}>
                                                        <LabeledItem label={messages.deliveries.sender.name}>{delivery.senderName}</LabeledItem>
                                                        <LabeledItem label={messages.deliveries.sender.email}>
                                                            <Link href={`mailto:${delivery.senderEmail}`}>{delivery.senderEmail}</Link>
                                                        </LabeledItem>
                                                        <LabeledItem label={messages.deliveries.sender.phone}>
                                                            <Link href={`tel:${delivery.senderPhone}`}>{delivery.senderPhone}</Link>
                                                        </LabeledItem>
                                                    </Grid>
                                                </BoxedPaper>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.journal}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <List>
                                                    {delivery.created && (
                                                        <ListItem>
                                                            <ListItemIcon><AddIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.stateEnum.Created} secondary={<DateComponent date={delivery.created} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.stored && (
                                                        <ListItem>
                                                            <ListItemIcon><SaveAltIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.stateEnum.Stored} secondary={<DateComponent date={delivery.stored} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.pickedUp && (
                                                        <ListItem>
                                                            <ListItemIcon><DoneIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.stateEnum.PickedUp} secondary={<DateComponent date={delivery.pickedUp} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.retrieved && (
                                                        <ListItem>
                                                            <ListItemIcon><CloseIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.stateEnum.Retrieved} secondary={<DateComponent date={delivery.retrieved} />} />
                                                        </ListItem>
                                                    )}
                                                    {delivery.cancelled && (
                                                        <ListItem>
                                                            <ListItemIcon><CloseIcon/></ListItemIcon>
                                                            <ListItemText primary={messages.deliveries.stateEnum.Canceled} secondary={<DateComponent date={delivery.cancelled} />} />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    {(delivery.reservationStart || delivery.reservationEnd) &&
                                    <Grid container spacing={gs}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{messages.deliveries.reservation.title}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Box p={2}>
                                                    <Grid container spacing={gs}>
                                                        {delivery.reservationStart &&
                                                        <Grid item>
                                                            <LabeledData label={messages.deliveries.reservation.start}>
                                                                <DateComponent date={delivery.reservationStart} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                        {delivery.reservationEnd &&
                                                        <Grid item>
                                                            <LabeledData label={messages.deliveries.reservation.end}>
                                                                <DateComponent date={delivery.reservationEnd} />
                                                            </LabeledData>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {delivery.notifiedMaxStorageDays && (
                                <Box my={1}>
                                    <Alert severity="warning">
                                        <AlertTitle>{messages.deliveries.maxStorageDays}</AlertTitle>
                                        <DateComponent date={delivery.notifiedMaxStorageDays}/>
                                        <br/>
                                        {messages.deliveries.maxStorageDaysNotified}
                                    </Alert>
                                </Box>
                            )}
                            {delivery.notifiedCriticalStorageDays && (
                                <Box my={1}>
                                    <Alert severity="error">
                                        <AlertTitle>{messages.deliveries.criticalStorageDays}</AlertTitle>
                                        <DateComponent date={delivery.notifiedCriticalStorageDays}/>
                                        <br/>
                                        {messages.deliveries.criticalStorageDaysNotified}
                                    </Alert>
                                </Box>
                            )}
                        </Grid>
                        {loadingEvents && <Loading />}
                        {eventsError && <FormattedAlert title={messages.errorDialogTitle} info={eventsError.message || messages.errorDialogMessage} />}
                        {events &&
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{messages.deliveries.eventQueue}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <EventsTable delivery={delivery} events={events} reload={() => handleLoadEvents()} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{messages.deliveries.notes}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth multiline variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align="right" component="div">
                                        <Button variant="contained" color="primary"
                                                disabled={(delivery.notes || '') == notes}
                                                onClick={handleSaveNotes}>{messages.save}</Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <RestoreDeliveryDialog delivery={delivery} open={restoreDeliveryMode} onClose={toggleRestoreDelivery} />
                    {deleteMode && (
                        <ConfirmDialog {...messages.deliveries.delete} reject={toggleDelete} resolve={deleteDelivery}/>
                    )}
                    {markMaxStorageDaysMode && (
                        <ConfirmDialog {...messages.deliveries.markMaxStorageDays} reject={toggleMarkMaxStorageDays} resolve={markMaxStorageDays}/>
                    )}
                </div>
            )}
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
};
