import * as React from "react";
import {createElement} from "react";
import {TextField} from "@material-ui/core";

interface SimpleTextInputProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    rows?: number;
}

export function SimpleTextInput(props: SimpleTextInputProps) {
    const {label, value, onChange} = props;
    return (
        <TextField variant="outlined" fullWidth label={label}
                   value={value}
                   multiline={!!props.rows}
                   rows={props.rows}
                   onChange={(e) => onChange(e.target.value)} />
    );
}
