import {useHistory} from "react-router";
import {createElement, useEffect, useState} from "react";
import {Delivery} from "../../domain/types";
import {ApiError} from "../../Api";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {Chip} from "@material-ui/core";
import {ErrorPopup} from "../ErrorPopup";
import {messages} from "../../i18n";
import {userStore} from "../../domain/UserStore";

export const DeliveryLinkComponent = (props: { id: string }) => {
    const history = useHistory();

    const [delivery, setDelivery] = useState<Delivery|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        if (props.id) {
            DeliveriesProvider.get(userStore.getTenantId(), props.id)
                .then(setDelivery)
                .catch(toggleError);
        }
    }, [setDelivery, setError]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <div>
            {delivery && (
                <Chip style={{cursor: 'pointer'}}
                      label={delivery.carrierId + ' - ' + delivery.deliveryId}
                      onClick={() => history.push(`/deliveries/${delivery.id}`)} />
            )}
            {error && (
                <Chip label={messages.deliveries.notFound(props.id)} title={`${error.code}: ${error.message}`} />
            )}
        </div>
    );
};
