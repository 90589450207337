import * as React from 'react';
import {Cube} from "../../domain/types";
import {gs} from "../../theme";
import {Grid, Tooltip} from "@material-ui/core";
import {createElement, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {maxLen} from "../../tools";
import {messages} from "../../i18n";

interface CubeNameDisplayProps{
    cube: Cube;
    displayShortDescription: boolean;
}

const DESCRIPTION_MAX_LENGTH = 25;

export function CubeDescriptionDisplayComponent({ cube, displayShortDescription }: CubeNameDisplayProps){

    const [cubeDesc, setCubeDesc] = useState(cube.description);

    useEffect( () => {
        if(displayShortDescription){
            setCubeDesc(maxLen(cubeDesc, DESCRIPTION_MAX_LENGTH));
        }
    }, []);

    return(
       <Tooltip title={<CubeDescriptionDisplayTooltip cubeId = {cube.cubeId} cubeDescription={cube.description} cubeConnected={cube.connected} />}  >
           <div>
               {cubeDesc}
           </div>
       </Tooltip>
    )
}

interface CubeDescriptionDisplayTooltipProps{
    cubeId: string;
    cubeDescription: string;
    cubeConnected: boolean;
}

function CubeDescriptionDisplayTooltip(props: CubeDescriptionDisplayTooltipProps){
    return(
        <Grid container spacing={gs} alignItems="center" direction="column">
            <Grid item>
                <label>{messages.cubes.cubeId}: {props.cubeId}</label>
            </Grid>
            <Grid item>
                <label>{messages.cubes.description}: {props.cubeDescription}</label>
            </Grid>
            <Grid item>
                <CubeConnectedComponent connected={props.cubeConnected} />
            </Grid>
        </Grid>
    )
}