import * as React from "react";
import {createElement, useCallback} from "react";
import {CubeState} from "../../domain/types";
import {MenuItem, TextField} from "@material-ui/core";
import {messages} from "../../i18n";

interface CubeStateInputProps {
    label: string;
    value: CubeState;
    onChange: (state: CubeState) => void;
}

export function CubeStateInput(props: CubeStateInputProps) {
    const {label, value, onChange} = props;

    const handleChange = useCallback((value: string) => {
        onChange(value as CubeState);
    }, []);

    return (
        <TextField variant="outlined" fullWidth select label={label}
                    value={value} onChange={(e) => handleChange(e.target.value)}>
            {Object.keys(CubeState).map(key =>
                <MenuItem key={key} value={key}>{messages.cubes.states[key as keyof typeof CubeState]}</MenuItem>
            )}
        </TextField>
    );

}

