import {RouteComponentProps, useHistory} from "react-router";
import {createElement, useEffect, useState} from "react";
import {
    Box,
    Breadcrumbs,
    Grid, LinearProgress,
    Paper,
    Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@material-ui/core";
import {Cube, Page, Pageable, Utilization} from "../../domain/types";
import {ContainerLayout} from "../ContainerLayout";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {Loading} from "../Loading";
import {SortableTable} from "../SortableTable";
import {pad} from "../../tools";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";
import {BlockSvg} from "../BlockSvg";
import buildingBlocks from "../../assets/undraw_building_blocks_n0nc.svg";
import {CubesProvider} from "../../domain/CubesProvider";
import {paging} from "../../domain/paging";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";
import React from 'react';
import {TablePagePagination} from "../base/TablePagePagination";

export const CubesList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [cubes, setCubes] = useState<Page<Cube>|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    const load = (pageable: Pageable) => {
        setLoading(true);
        CubesProvider.list(userStore.getTenantId(), pageable)
            .then((page) => {
                setCubes(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load(paging.defaultPageable());
    }, [setCubes]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.cubes.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.cubes.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        { cubes == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        { cubes != undefined &&
                        <React.Fragment>
                            <TableContainer>
                                <CubeTable list={cubes.content} />
                            </TableContainer>
                            <TablePagePagination page={cubes} onPagingChanged={load}/>
                            {loading &&
                            <LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
};

type sortKeys = 'cubeId' | 'description' | 'connected' | 'utilization';

class CubeTable extends SortableTable<Cube, sortKeys> {

    constructor(props: any) {
        super(props);
        this.state = {
            orderAsc: true,
            orderBy: 'description'
        };
    }

    private static formattedUtilization(u: Utilization) {
        return pad(u.available, 3) + pad(u.total, 3);
    }

    protected descendingComparator(a: Cube, b: Cube, asc: boolean, orderBy: sortKeys): number {
        let aValue, bValue;
        switch (orderBy) {
            case "cubeId":
                aValue = a.cubeId;
                bValue = b.cubeId;
                break;
            case "description":
                aValue = a.description;
                bValue = b.description;
                break;
            case "connected":
                aValue = a.connected ? "1" : "0";
                bValue = b.connected ? "1" : "0";
                break;
            case "utilization":
                aValue = CubeTable.formattedUtilization(a.utilization);
                bValue = CubeTable.formattedUtilization(b.utilization);
                break;
            default:
                throw new Error('You did not select a valid comparision field');
        }
        const v = aValue.localeCompare(bValue);
        if(asc) {
            return v;
        }
        return v * -1;
    }

    render() {
        const sorted = this.getSorted();
        const {orderAsc, orderBy} = this.state;
        return (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'description'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('description')}
                            >
                                {messages.cubes.singular}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'cubeId'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('cubeId')}
                            >
                                {messages.cubes.cubeId}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{width: '20%'}} align="center">
                            <TableSortLabel
                                active={orderBy === 'connected'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('connected')}
                            >
                                {messages.cubes.connected}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell style={{width: '20%'}}>
                            <TableSortLabel
                                active={orderBy === 'utilization'}
                                direction={orderAsc ? 'asc' : 'desc'}
                                onClick={(e) => this.onSort('utilization')}
                            >
                                {messages.cubes.utilization.label}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { sorted && sorted.length > 0 && sorted.map((cube) => (
                        <CubeRow cube={cube} key={cube.cubeId} />
                    ))}
                    { sorted && sorted.length == 0 && (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Box p={6}>
                                    <Typography variant="h6" align="center">{messages.cubes.noCubes}</Typography>
                                </Box>
                                <BlockSvg src={buildingBlocks} />
                                <Box p={6} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

const CubeRow = (props: { cube: Cube }) => {
    const history = useHistory();

    const {cube} = props;
    return (
        <TableRow hover style={{cursor: 'pointer'}} onClick={() => history.push('/cubes/' + cube.cubeId)}>
            <TableCell>{cube.description}</TableCell>
            <TableCell>{cube.cubeId}</TableCell>
            <TableCell align="center"><CubeConnectedComponent connected={cube.connected} /></TableCell>
            <TableCell><CubeUtilizationComponent utilization={cube.utilization} /></TableCell>
        </TableRow>
    );
};
