import {RouteComponentProps} from "react-router";
import {useEffect, useState, createElement} from "react";
import {userStore} from "../domain/UserStore";
import {ContainerLayout} from "./ContainerLayout";
import {Grid, List, ListItem, ListItemText, Paper, Typography} from "@material-ui/core";
import {Tenant} from "../domain/types";
import {messages} from "../i18n";
import {FormattedAlert} from "./FormattedAlert";
import {TenantsProvider} from "../domain/TenantsProvider";
import {Loading} from "./Loading";

export const TenantSelector = (props: { onTenantSelected: () => void } & RouteComponentProps) => {
    const [tenants, setTenants] = useState<Tenant[]|undefined>(undefined);

    const selectTenant = (tenant: Tenant) => {
        userStore.setTenant(tenant);
        props.onTenantSelected();
        props.history.push('/deliveries');
    };

    useEffect(() => {
        TenantsProvider.list()
            .then(tenants => {
                if (tenants.length === 1) {
                    console.log('There is only 1 tenant available, auto-select tenant ', tenants[0]);
                    selectTenant(tenants[0]);
                    props.onTenantSelected();
                    return;
                }
                setTenants(tenants);
            });
    }, [props]);

    return (
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>{messages.tenants.plural}</Typography>
                    <Typography variant="body1">{messages.tenants.select}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {tenants == undefined && <Loading />}
                    {tenants && tenants.length > 0 && (
                        <Paper>
                            <List>
                                {tenants.map((t) =>
                                    <ListItem key={t.id} button onClick={() => selectTenant(t)} >
                                        <ListItemText primary={t.name || t.id} />
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    )}
                    {tenants && tenants.length == 0 &&
                    <FormattedAlert severity="error" title={messages.tenants.noTenant.title} info={messages.tenants.noTenant.info} />
                    }
                </Grid>
            </Grid>
        </ContainerLayout>
    );
};
