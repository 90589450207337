import * as React from "react";
import {createElement, useCallback, useEffect, useState} from "react";
import {CubeDto, CubeSettingDto, CubeState} from "../../domain/types";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {BlockSvg} from "../BlockSvg";
import treeSwing from "../../assets/undraw_tree_swing_646s.svg";
import {gs} from "../../theme";
import {CarrierSelectInput} from "../base/CarrierSelectInput";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {EditIcon} from "../../icons";
import {CubeStateDisplay} from "../base/CubeStateDisplay";
import {CubeStateInput} from "../base/CubeStateInput";

export const CubeSettingsList = (props: { cube: CubeDto, saveSettings: (cubeSetting: CubeSettingDto) => void }) => {
    const { cube } = props;
    const [ error, setError ] = useState<ApiError|undefined>(undefined);
    const [ showSetSettings, setShowSetSettings ] = useState<boolean>();
    const [ editingCubeSetting, setEditingCubeSetting ] = useState<CubeSettingDto>();

    const toggleError = useCallback((error?: ApiError) => {
        setError(error);
    }, []);

    const closeSetCubeSettingsDialog = useCallback(() => {
        setShowSetSettings(false);
        setEditingCubeSetting(undefined);
    }, []);

    return (
        <div>
            <Paper>
                {!cube && (
                    <Box p={5}>
                        <Loading/>
                    </Box>
                )}
                {cube.cubeSettings && (
                    <MachineIdsTable cube={props.cube} setShowSetSettings={setShowSetSettings} setEditingCubeSetting={setEditingCubeSetting} />
                )}
                <SetCubeSettingsDialog saveSetting={props.saveSettings} cubeId={cube.cubeId} toggleError={toggleError} closeDialog={closeSetCubeSettingsDialog} currentSettings={editingCubeSetting} showSetSettings={!!showSetSettings} />
            </Paper>
            {error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </div>
    );
};

interface SetCubeSettingsDialogDialogProps {
    cubeId: string;
    currentSettings?: CubeSettingDto;
    toggleError: (error: ApiError) => void;
    showSetSettings: boolean;
    closeDialog: () => void;
    saveSetting: (cubeSetting: CubeSettingDto) => void;
}

const SetCubeSettingsDialog = ({ toggleError, cubeId, closeDialog, showSetSettings, currentSettings, saveSetting }: SetCubeSettingsDialogDialogProps) => {
    const [ selectedCarrier, setSelectedCarrier ] = useState<string>();
    const [ alphanumericMachineId, setAlphanumericMachineId ] = useState<string>();
    const [ state, setState ] = useState<CubeState>(CubeState.Enabled);
    const [ routeCode, setRouteCode ] = useState<string>();
    const [ carrierEmail, setCarrierEmail ] = useState<string>();
    const [ serviceAreaCode, setServiceAreaCode ] = useState<string>();

    useEffect(() => {
        setSelectedCarrier(currentSettings?.carrierId);
        setAlphanumericMachineId(currentSettings?.alphanumericMachineId);
        setRouteCode(currentSettings?.routeCode);
        setState(currentSettings?.state || CubeState.Enabled);
        setCarrierEmail(currentSettings?.carrierEmail);
        setServiceAreaCode(currentSettings?.serviceAreaCode);
    }, [currentSettings]);

    const handleSaveClicked = useCallback(async () => {
        try {
            await saveSetting({
                cubeId,
                carrierId: selectedCarrier,
                carrierEmail,
                alphanumericMachineId,
                state,
                routeCode,
                serviceAreaCode
            });
            closeDialog();
        } catch (err) {
            toggleError(err);
        }
    }, [cubeId, alphanumericMachineId, state, selectedCarrier, carrierEmail, routeCode, serviceAreaCode]);

    return (
        <Dialog open={Boolean(showSetSettings)} onClose={closeDialog} maxWidth="md">
            <DialogTitle>{messages.cubes.addCubeSettings}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item xs={12}>
                        <CarrierSelectInput label={messages.cubes.accessCodes.carrierAccessCode.carrierId} carrierId={selectedCarrier} onChange={setSelectedCarrier} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined" fullWidth
                                   value={alphanumericMachineId} onChange={(e) => setAlphanumericMachineId(e.target.value)}
                                   label={messages.cubes.alphanumericMachineId} />
                    </Grid>
                    <Grid item xs={6}>
                        <CubeStateInput label={messages.cubes.state} value={state} onChange={setState} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth type="email"
                                   value={carrierEmail} onChange={(e) => setCarrierEmail(e.target.value)}
                                   label={messages.cubes.carrierEmail} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined" fullWidth
                                   value={routeCode} onChange={(e) => setRouteCode(e.target.value)}
                                   label={messages.cubes.routeCode} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField variant="outlined" fullWidth
                                   value={serviceAreaCode} onChange={(e) => setServiceAreaCode(e.target.value)}
                                   label={messages.cubes.serviceAreaCode} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>{messages.cancel}</Button>
                <Button color="primary" disabled={!selectedCarrier || (!alphanumericMachineId && !carrierEmail)} onClick={handleSaveClicked}>{messages.save}</Button>
            </DialogActions>
        </Dialog>
    );
};

interface MachineIdsProps {
    cube: CubeDto;
    setShowSetSettings: (state: boolean) => void;
    setEditingCubeSetting: (cubeSetting: CubeSettingDto) => void;
}

const MachineIdsTable = (props: MachineIdsProps) => {
    const { cube } = props;
    const cubeSettings = cube.cubeSettings;

    const handleEditClicked = useCallback((cubeSetting: CubeSettingDto) => {
        props.setEditingCubeSetting(cubeSetting);
        props.setShowSetSettings(true);
    }, []);

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        {messages.cubes.carrier}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.machineId}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.alphanumericMachineId}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.carrierEmail}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.state}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.routeCode}
                    </TableCell>
                    <TableCell>
                        {messages.cubes.serviceAreaCode}
                    </TableCell>
                    <TableCell style={{textAlign: 'right'}} >
                        <Button onClick={() => props.setShowSetSettings(true)} variant="contained">{messages.cubes.addCubeSettings}</Button>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { cubeSettings && cubeSettings.length > 0 && cubeSettings.map(cubeSetting => (
                    <TableRow key={cubeSetting.carrierId}>
                        <TableCell style={{whiteSpace: 'nowrap'}}>{cubeSetting.carrierId}</TableCell>
                        <TableCell>{cubeSetting.machineId}</TableCell>
                        <TableCell>{cubeSetting.alphanumericMachineId}</TableCell>
                        <TableCell>{cubeSetting.carrierEmail}</TableCell>
                        <TableCell><CubeStateDisplay state={cubeSetting?.state || CubeState.Enabled} /></TableCell>
                        <TableCell>{cubeSetting.routeCode}</TableCell>
                        <TableCell>{cubeSetting.serviceAreaCode}</TableCell>
                        <TableCell style={{ display: "flex", justifyContent: "center" }}>
                            <IconButton onClick={() => handleEditClicked(cubeSetting)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                { cubeSettings && cubeSettings.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={7}>
                            <Box p={6}>
                                <Typography variant="h6" align="center">{messages.cubes.noSettingsFound}</Typography>
                            </Box>
                            <BlockSvg src={treeSwing} />
                            <Box p={6} />
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}
