import * as React from "react";
import {createElement, useEffect, useState} from "react";
import { ArrowForwardIos, ArrowBackIos } from "../../icons";
import {Carrier, Cube, Page} from "../../domain/types";
import {gs} from "../../theme";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography
} from "@material-ui/core";
import {messages} from "../../i18n";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import SelectableCarriersList from "./selectableCarriersList";

interface ActivatedCarriersComponentProps {
    cube: Cube;
    carriers: Page<Carrier>|undefined;
    loadingCarriers: boolean;
}

export function ActivatedCarriersComponent({cube, carriers, loadingCarriers}: ActivatedCarriersComponentProps) {
    const {accessCodes} = cube;
    const [editActivatedCarriersMode, setEditActivatedCarriersMode] = useState<boolean>(false);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [selectedInDeactivatedCarriers, setSelectedInDeactivatedCarriers] = useState<Carrier[]>([]);
    const [selectedInActivatedCarriers, setSelectedInActivatedCarriers] = useState<Carrier[]>([]);
    const [savingCarriers, setSavingCarriers] = useState<boolean>(false);
    const [activatedCarrierIds, setActivatedCarrierIds] = useState<string[]>([]);
    const activatedCarriers = carriers?.content.filter(carrier => activatedCarrierIds?.includes(carrier.id)) || [];
    const deactivatedCarriers = carriers?.content.filter(carrier => !activatedCarrierIds?.includes(carrier.id)) || [];


    useEffect(() => {
        setActivatedCarrierIds(Object.entries(accessCodes.carrier ?? {}).map(([carrierId]) => carrierId));
    }, [accessCodes]);



    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const activateCarriersClicked = () => {
        if (selectedInDeactivatedCarriers.length) {
            const selectedCarrierIds = selectedInDeactivatedCarriers.map(carrier => carrier.id);
            setActivatedCarrierIds([...activatedCarrierIds, ...selectedCarrierIds])
            setSelectedInActivatedCarriers([]);
            setSelectedInDeactivatedCarriers([]);
        }
    };

    const deactivateCarriersClicked = () => {
        if (selectedInActivatedCarriers.length) {
            const selectedCarrierIds = selectedInActivatedCarriers.map(carrier => carrier.id);
            const newActivatedCarrierIds = activatedCarrierIds.filter(id => !selectedCarrierIds.includes(id));
            setActivatedCarrierIds(newActivatedCarrierIds);
            setSelectedInActivatedCarriers([]);
            setSelectedInDeactivatedCarriers([]);
        }
    };

    const putActivatedCarriers = () => {
        setSavingCarriers(true);
        CubesProvider.putActivatedCarriers(userStore.getTenantId(), cube.cubeId, activatedCarrierIds ?? [])
            .then(() => {
                setEditActivatedCarriersMode(false);
                setSavingCarriers(false);
            })
            .catch(toggleError);
    };


    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Typography variant="subtitle1"><strong>{messages.cubes.carriers.activatedCarriers}</strong></Typography>
                {activatedCarrierIds?.length ? <Typography variant="body1">{activatedCarrierIds.join(", ")}</Typography> : messages.notAvailable}
                <Box my={2}/>
                <Button size="small" variant="outlined" onClick={() => setEditActivatedCarriersMode(true)}>{messages.edit}</Button>

                <Dialog open={editActivatedCarriersMode} onClose={() => setEditActivatedCarriersMode(false)} maxWidth="lg">
                    <DialogTitle>{messages.cubes.carriers.changeActivatedCarriers}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gs}>
                            <Grid item md={4} xs={12}>
                                <Box minWidth={200}><Typography variant="subtitle2">{messages.cubes.carriers.deactivatedCarriers}</Typography></Box>
                                <SelectableCarriersList carriers={deactivatedCarriers} checked={selectedInDeactivatedCarriers} setChecked={setSelectedInDeactivatedCarriers} />
                            </Grid>
                            <Grid item md={2}>
                                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                    <Box>
                                        <Button onClick={activateCarriersClicked}><ArrowForwardIos /></Button>
                                        <Button onClick={deactivateCarriersClicked}><ArrowBackIos /></Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box minWidth={200}><Typography variant="subtitle2">{messages.cubes.carriers.activatedCarriers}</Typography></Box>
                                <SelectableCarriersList carriers={activatedCarriers} checked={selectedInActivatedCarriers} setChecked={setSelectedInActivatedCarriers} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditActivatedCarriersMode(false)}>{messages.cancel}</Button>
                        <Button color="primary" disabled={savingCarriers || loadingCarriers || !carriers?.content?.length} onClick={() => putActivatedCarriers()}>{messages.save}</Button>
                    </DialogActions>
                </Dialog>
                { error && (
                    <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
                )}
            </Grid>
        </Grid>
    );
}
