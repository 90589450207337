import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton
} from "@material-ui/core";
import {SettingsIcon} from "../../icons";
import {gs} from "../../theme";
import {messages} from "../../i18n";

interface TableColumnFilterProps {
    availableColumns: string[];
    selectedColumns: string[];
    onChange: (columns: string[]) => void;
}

export function TableColumnFilter(props: TableColumnFilterProps) {
    const {availableColumns, selectedColumns, onChange} = props;
    const [configure, setConfigure] = useState<boolean>(false);
    return (
        <React.Fragment>
            <IconButton onClick={() => setConfigure(true)}>
                <SettingsIcon />
            </IconButton>
            <TableColumnFilterDialog
                availableColumns={availableColumns} selectedColumns={selectedColumns}
                open={configure}
                onConfirm={(columns) => onChange(columns)} onClose={() => setConfigure(false)} />
        </React.Fragment>
    );
}

interface TableColumnFilterDialog {
    open: boolean;
    availableColumns: string[];
    selectedColumns: string[];
    onConfirm: (columns: string[]) => void;
    onClose: () => void;
}

function TableColumnFilterDialog(props: TableColumnFilterDialog) {
    const {open, onConfirm, onClose, availableColumns} = props;
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    useEffect(() => {
        setSelectedColumns([... props.selectedColumns]);
    }, [open, availableColumns]);

    const isSelected = (key: string): boolean => {
        return selectedColumns.indexOf(key) != -1;
    };

    const handleCheck = (key: string, value: boolean) => {
        if(value) {
            setSelectedColumns([... selectedColumns, key]);
        }else {
            const index = selectedColumns.indexOf(key);
            if(index != -1) {
                selectedColumns.splice(index, 1);
                setSelectedColumns([... selectedColumns]);
            }
        }
    };

    const handleConfirm = () => {
        onConfirm(selectedColumns);
        onClose();
    };

    return(
        <Dialog open={open} onClose={() => onClose()}>
            <DialogContent>
                <Grid container spacing={gs}>
                    {availableColumns.map((ac) =>
                    <Grid key={ac} item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={isSelected(ac)} onChange={(e) => handleCheck(ac, e.target.checked)} color="primary" />}
                            label={ac}
                        />
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{messages.cancel}</Button>
                <Button variant="contained" color="primary" onClick={() => handleConfirm()}>{messages.confirm}</Button>
            </DialogActions>
        </Dialog>
    );
}
