import {DateTime} from "luxon";

export interface Page<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    number: number;
    numberOfElements: number;
    size: number;
    last: boolean;
    first: boolean;
    hasContent: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
}

export interface Pageable {
    pageNumber: number;
    pageSize: number;
    sort?: string;
    direction?: 'asc' | 'desc';
}

export enum DeliveryService {
    Outbound = "Outbound",
    Inbound = "Inbound"
}

export enum DeliveryState {
    Created = "Created",
    Stored = "Stored",
    PickedUp = "PickedUp",
    Retrieved = "Retrieved",
    Canceled = "Canceled",
}

export interface Delivery {
    id: string;
    carrierId: string;
    deliveryId: string;
    service: DeliveryService;
    state: DeliveryState;
    cubeId: string;
    boxNumber: string;
    reservedBoxNumber: string;
    carrierDeliveryCode?: string;
    carrierRetrievalCode: string;
    recipientPickupCode?: string;

    signature?: string;

    created: DateTime;
    ended: DateTime;

    reservationStart?: DateTime;
    reservationEnd?: DateTime;

    stored?: DateTime;
    pickedUp?: DateTime;
    retrieved?: DateTime;
    cancelled?: DateTime;

    notifiedMaxStorageDays?: DateTime;
    notifiedCriticalStorageDays?: DateTime;
    disabledCarrierNotification?: boolean;

    senderName?: string;
    senderEmail?: string;
    senderPhone?: string;

    notes: string;
}

export interface DeliveryCreateRequest {
    carrierId: string;
    cubeId: string;
    boxType: string;
    parcelNumber: string;
    disableCarrierNotification: boolean;
}

export interface DeliveryFilter {
    fulltext?: string;
    states: DeliveryState[];
    carriers: string[];
    services: DeliveryService[];
    cubes: string[];
    from?: DateTime;
    until?: DateTime;
}

export class DeliveryFilterWrapper {

	readonly filter: DeliveryFilter;

	constructor(filter: DeliveryFilter) {
		this.filter = filter;
	}

	get empty(): boolean {
		return !this.filter.fulltext && this.filter.states.length === 0 && this.filter.carriers.length === 0 && this.filter.services.length === 0 && this.filter.cubes.length === 0 && !this.filter.from && !this.filter.until;
	}

}

export interface Utilization {
    total: number;
    occupied: number;
    available: number;
    disabled: number;
}

export enum AppLockStatus {
    Open = "Open",
    Closed = "Closed",
    Breakin = "Breakin",
    Blocked = "Blocked",
}

export interface Occupancy {
    uuid: string;
    cubeId: string;
    boxNumber: string;
    lock: string;
    accessCode: string;
    accessKeys: string[];
    created: DateTime;
    updated: DateTime;
    ended?: DateTime;
    deliveryId: string;
    deliveryUuid: string;
}

export interface CarrierAccessCode {
    accessCode: string;
    pin: string;
    fixedAccessCode: string;
}

export interface AccessCodes {
    carrier: Record<string, CarrierAccessCode>;
    readOnly: string;
    openNonOccupied: string;
    fullAccess: string;
}

export interface Cube {
    cubeId: string;
    description: string;
    connected: boolean;
    latitude: number;
    longitude: number;
    utilization: Utilization;
    imageUrl: string;
    downTimes: CubeDownTime[];
    accessCodes: AccessCodes;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    countryCode: string;
}

export interface CubeDto extends Cube {
    cubeSettings: CubeSettingDto[];
}

export enum CubeState {
    Enabled = "Enabled",
    FirstMileOnly = "FirstMileOnly",
    LastMileOnly = "LastMileOnly",
    Disabled = "Disabled"
}

export interface CubeSettingDto {
    cubeId?: string;
    carrierId?: string;
    machineId?: number;
    alphanumericMachineId?: string;
    state: CubeState;
    routeCode?: string;
    serviceAreaCode?: string;
    carrierEmail?: string;
}

export interface CubeDownTime {
    key: string;
    startDate: DateTime;
    endDate: DateTime;
    comment: string;
}

export interface Box {
    number: string;
    description: string;
    lock: string;
    secondaryLock?: string;
    types: string[];
    lockStatus: AppLockStatus;
    secondaryLockStatus?: AppLockStatus;
    disabled: boolean;
    occupancies: Occupancy[];
}

export interface SetBoxStateRequest {
    disabled: boolean;
}

export interface Tenant {
    id: string;
    name: string;
}

export enum UserRole {
    Admin = 'Admin',
    User = 'User'
}

export interface User {
    uuid: string;
    username: string;
    email: string;
    role: UserRole;
    active: boolean;
    carrierId: string;
    root: boolean;
    apiToken?: string;
}

export interface UserCreateRequest {
    email: string;
    role: UserRole;
}

export enum QueueStatus {
    Created = "Created",
    Failed = "Failed",
    Succeeded = "Succeeded"

}

export interface QueueItem {
    uuid: string;
    version: number;
    deliveryId: string;
    status: QueueStatus;
    state: string;
    actionType: string;
    eventType: string;
    scannedBarcode: string;
    signature: string;
    code: string;
    name: string;
    email: string;
    phone: string;
    request: string;
    response: string;
    failureCount: number;
    created: DateTime;
    retry: DateTime;
    delivered: DateTime;
    expired: DateTime;
    carrierId: string;
}

export interface CarrierToken {
    uuid: string;
    carrierId: string;
    createdAt: DateTime;
    lastUsedAt: DateTime;
}

export interface CarrierTokenCreateResponse extends CarrierToken {
    rawToken: string;
}

export enum CallbackMethod {
    POST = "POST",
    PUT = "PUT",
}

export enum CarrierAuthType {
    Raw = "Raw",
    Basic = "Basic",
    Bearer = "Bearer",
    None = "None"
}

export enum SignatureOutputFormat {
    Jpg = "Jpg",
    Png = "Png",
    PngNonAlpha = "PngNonAlpha",
    JsonPointList = "JsonPointList"
}

export interface SignatureConfiguration {
    outputFormat: SignatureOutputFormat;
    width: number;
    height: number;
    strokeMinWidth: number;
    strokeMaxWidth: number;
}

export enum FirstMileField {
    Name = "Name",
    Email = "Email",
    Phone = "Phone"
}

export interface FirstMileConfiguration {
    availableFields: FirstMileField[];
    requiredFields: FirstMileField[];
    allContactFieldsRequired: boolean;
    verifyContactData: boolean;
    reopenCountdown?: number;
    offerReceipt?: boolean;
}

export interface LastMileConfiguration {
    recipientNameRequired?: boolean;
    deliveryWithoutReservation?: boolean;
    carrierInterfaceShowsDeliveryOption?: boolean;
    reopenCountdown?: number;
}

export interface MessageDialogConfiguration {
    hideCloseButton?: boolean;
    autoCloseTimer?: number;
}

export interface Carrier {
    id: string;
    name: string;
    dataRetentionPolicyDays: number;
    maxStorageHours: number;
    callbackUrl: string;
    callbackMethod: CallbackMethod;
    signatureConfiguration: SignatureConfiguration;
    firstMileConfiguration: FirstMileConfiguration;
    lastMileConfiguration?: LastMileConfiguration;
    messageDialogConfiguration?: MessageDialogConfiguration;
    apiCredentials?: ApiCredentials;
    publicKey?: string;
    autoCarrierRetrieval?: boolean;
    appName?: string;
    appCode?: string;
    appVersion?: string;
}

export interface ApiCredentials {
    baseUrl?: string;
    username?: string;
    password?: string;
    token?: string;
    authType?: CarrierAuthType;
}

export interface CarrierCreateRequest {
    id: string;
    name: string;
}

export interface CarrierUpdateRequest extends Omit<Carrier, 'id'> {
}

export interface Statistics {
    boxTypes: { [key: string]: number; };
    cubes: { [key: string]: number; };
    states: { [key: string]: number; };
    services: { [key: string]: number; };
}
