import * as React from "react";
import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {Lock, LockOpen, Error, NotificationImportant} from "@material-ui/icons";
import {lookupByString, messages} from "../../i18n";
import {AppLockStatus} from "../../domain/types";

interface CubeBoxLockStateComponentProps {
    lockState?: AppLockStatus;
}

export function CubeBoxLockStateComponent(props: CubeBoxLockStateComponentProps) {
    const {lockState} = props;

    return(<Chip label={getLockStatusLabel(lockState)} icon={getLockStatusIcon(lockState)}/>);
}

function getLockStatusIcon(state?: AppLockStatus) {
    switch (state) {
        case AppLockStatus.Open:
            return <LockOpen />;
        case AppLockStatus.Closed:
            return <Lock />;
        case AppLockStatus.Blocked:
            return <Error />;
        case AppLockStatus.Breakin:
            return <NotificationImportant />;
        default:
            return undefined;
    }
}

function getLockStatusLabel(state?: AppLockStatus): string {
    if(state) {
        return lookupByString(messages.cubes.boxes.lockState, state);
    }
    return messages.cubes.boxes.lockState.unknown;
}
