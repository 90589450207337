import {CarrierToken, CarrierTokenCreateResponse, Page, Pageable} from "./types";
import {paging} from "./paging";
import {Api} from "../Api";
import {luxonDate, luxonDateOrUndefined} from "../tools";

export class CarrierTokensProvider {

    static list(tenantId: string, pageable: Pageable) {
        const path = paging.attachToUrl(pageable, `tenants/${tenantId}/carrier-tokens`);
        return Api.GET<Page<CarrierToken>>(path)
            .then((response) => ({
                ... response,
                content: response.content.map(mapCarrierToken)
            }));
    }

    static listForCarrier(tenantId: string, carrierId: string) {
        const path = `tenants/${tenantId}/carrier-tokens/${carrierId}`;
        return Api.GET<CarrierToken[]>(path)
            .then((response) => response.map(mapCarrierTokenCreateResponse));
    }

    static create(tenantId: string, carrierId: string): Promise<CarrierTokenCreateResponse> {
        return Api.POST<CarrierTokenCreateResponse>(`tenants/${tenantId}/carrier-tokens`, { carrierId })
            .then(mapCarrierTokenCreateResponse);
    }
}

function mapCarrierToken(response: any): CarrierToken {
    return {
        ... response,
        createdAt: luxonDate(response.createdAt)
    };
}

function mapCarrierTokenCreateResponse(response: any): CarrierTokenCreateResponse {
    return {
        ... response,
        createdAt: luxonDate(response.createdAt),
        lastUsedAt: luxonDateOrUndefined(response.lastUsedAt)
    };
}
