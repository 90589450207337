import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import HelpIcon from '@material-ui/icons/Help';
import BuildIcon from '@material-ui/icons/Build';
import AssistantIcon from '@material-ui/icons/Assistant';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import AlarmIcon from "@material-ui/icons/Alarm";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NotesIcon from "@material-ui/icons/Notes";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

export {
    AddIcon,
    EditIcon,
    ChevronRightIcon,
    ClearIcon,
    DeleteIcon,
    KeyboardArrowLeftIcon,
    KeyboardArrowRightIcon,
    PhoneIcon,
    MailIcon,
    NotesIcon,
    CheckCircleIcon,
    ErrorIcon,
    ExpandMoreIcon,
    PersonIcon,
    AccountBalanceIcon,
    BrokenImageIcon,
    HelpIcon,
    BuildIcon,
    AssistantIcon,
    ArrowDropDownIcon,
    ToggleOnIcon,
    AlarmIcon,
    ExitToAppIcon,
    PersonAddIcon,
    SaveAltIcon,
    SearchIcon,
    DoneIcon,
    CloseIcon,
    SettingsIcon,
    FilterListIcon,
    ArrowBackIos,
    ArrowForwardIos
}
