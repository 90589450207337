import {RouteComponentProps, useHistory} from "react-router";
import React, {createElement, useEffect, useState} from "react";
import {Carrier} from "../../domain/types";
import {ApiError} from "../../Api";
import {userStore} from "../../domain/UserStore";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Grid, Paper, Typography} from "@material-ui/core";
import {Loading} from "../Loading";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {ErrorPopup} from "../ErrorPopup";
import {LabeledItem} from "../LabeledItem";
import {gs} from "../../theme";
import {IdDisplay} from "../IdDisplay";
import {CarrierTokens} from "./CarrierTokens";
import {FirstMileFieldsDisplay} from "../base/FirstMileFieldDisplay";
import {BooleanDisplay} from "../base/BooleanDisplay";

export const CarrierDetails = (props: RouteComponentProps<{ carrierId: string }>) => {
    const history = useHistory();
    const [carrier, setCarrier] = useState<Carrier|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);

    useEffect(() => {
        CarrierProvider.get(userStore.getTenantId(), props.match.params.carrierId)
            .then(c => {
                setCarrier(c);
            })
            .catch(toggleError)
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            {!carrier && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {carrier && (
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb route="/carriers" label={messages.carriers.plural}/>
                                <Crumb label={carrier.name || carrier.id}/>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" gutterBottom>
                                        {`${messages.carriers.singular}: ${carrier.name || carrier.id}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" color="primary" onClick={() => history.push(`/carriers/${carrier.id}/edit`)}>{messages.carriers.edit.title}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={2}>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label="ID">{carrier.id}</LabeledItem>
                                        <LabeledItem label={messages.carriers.name}>{carrier.name}</LabeledItem>
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.dataRetentionPolicy}>
                                            {carrier.dataRetentionPolicyDays || '-'}
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.maxStorageHours}>
                                            {carrier.maxStorageHours || '-'}
                                        </LabeledItem>
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.callbackMethod}>
                                            {carrier.callbackMethod || '-'}
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.callbackUrl}>
                                            {carrier.callbackUrl || '-'}
                                        </LabeledItem>
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.authType}>
                                            {carrier.apiCredentials?.authType}
                                        </LabeledItem>
                                        {carrier.apiCredentials?.token &&
                                        <LabeledItem label={messages.carriers.token}>
                                            <IdDisplay id={carrier.apiCredentials?.token} />
                                        </LabeledItem>
                                        }
                                        {carrier.apiCredentials?.username &&
                                        <LabeledItem label={messages.carriers.username}>
                                            {carrier.apiCredentials?.username}
                                        </LabeledItem>
                                        }
                                        {carrier.apiCredentials?.password &&
                                        <LabeledItem label={messages.carriers.password}>
                                            {carrier.apiCredentials?.password}
                                        </LabeledItem>
                                        }
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.signatureConfiguration.outputFormat}>
                                            {messages.carriers.signatureConfiguration.outputFormats[carrier.signatureConfiguration.outputFormat]}
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.signatureConfiguration.size}>
                                            {carrier.signatureConfiguration.width} / {carrier.signatureConfiguration.height}
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.signatureConfiguration.stroke}>
                                            {carrier.signatureConfiguration.strokeMinWidth} / {carrier.signatureConfiguration.strokeMaxWidth}
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.autoCarrierRetrieval}>
                                            <BooleanDisplay value={!!carrier.autoCarrierRetrieval} />
                                        </LabeledItem>
                                    </Grid>
                                    {carrier.apiCredentials && (
                                        <Grid container spacing={gs}>
                                            <LabeledItem label={messages.carriers.baseUrl}>
                                                {carrier.apiCredentials.baseUrl}
                                            </LabeledItem>
                                            <LabeledItem label={messages.carriers.appName}>
                                                {carrier.appName}
                                            </LabeledItem>
                                            <LabeledItem label={messages.carriers.appCode}>
                                                {carrier.appCode}
                                            </LabeledItem>
                                            <LabeledItem label={messages.carriers.appVersion}>
                                                {carrier.appVersion}
                                            </LabeledItem>
                                            <LabeledItem label={messages.carriers.clientId}>
                                                {carrier.apiCredentials.username}
                                            </LabeledItem>
                                            <LabeledItem label={messages.carriers.clientSecret}>
                                                {carrier.apiCredentials.password}
                                            </LabeledItem>
                                            {carrier.publicKey && (
                                                <LabeledItem label={messages.carriers.publicKey}>
                                                    {carrier.publicKey.substring(0, 3)}...
                                                </LabeledItem>
                                            )}
                                        </Grid>
                                    )}

                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.firstMileConfiguration.availableFields}>
                                            <FirstMileFieldsDisplay fields={carrier.firstMileConfiguration.availableFields} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.firstMileConfiguration.requiredFields}>
                                            <FirstMileFieldsDisplay fields={carrier.firstMileConfiguration.requiredFields} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.firstMileConfiguration.allContactFieldsRequired}>
                                            <BooleanDisplay value={carrier.firstMileConfiguration.allContactFieldsRequired} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.firstMileConfiguration.verifyContactData}>
                                            <BooleanDisplay value={carrier.firstMileConfiguration.verifyContactData} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.firstMileConfiguration.offerReceipt}>
                                            <BooleanDisplay value={!!carrier.firstMileConfiguration.offerReceipt} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.reopenCountdown}>
                                            {carrier.firstMileConfiguration?.reopenCountdown || 20000}
                                        </LabeledItem>
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.lastMileConfiguration.recipientNameRequired}>
                                            <BooleanDisplay value={!!carrier.lastMileConfiguration?.recipientNameRequired} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.lastMileConfiguration.carrierInterfaceShowsDeliveryOption}>
                                            <BooleanDisplay value={!!carrier.lastMileConfiguration?.carrierInterfaceShowsDeliveryOption} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.reopenCountdown}>
                                            {carrier.lastMileConfiguration?.reopenCountdown || 20000}
                                        </LabeledItem>
                                    </Grid>
                                    <Grid container spacing={gs}>
                                        <LabeledItem label={messages.carriers.messageDialogConfiguration.hideCloseButton}>
                                            <BooleanDisplay value={!!carrier.messageDialogConfiguration?.hideCloseButton} />
                                        </LabeledItem>
                                        <LabeledItem label={messages.carriers.messageDialogConfiguration.autoCloseTimer}>
                                            {carrier.messageDialogConfiguration?.autoCloseTimer}
                                        </LabeledItem>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <CarrierTokens carrierId={carrier.id} />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}

            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
}
