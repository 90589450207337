import {Component, createElement} from "react";
import {Typography} from "@material-ui/core";

type Properties = {
    label: string
}

export class LabeledData extends Component<Properties, {}> {

    render() {
        const label = this.props.label;
        return (
            <div>
                <Typography variant="overline">{label}</Typography>
                <Typography variant="body1" component="div">{this.props.children}</Typography>
            </div>
        );
    }
}
