import {DeliveryFilter} from "./types";
import {ParamValue, Urls} from "../urls";
import {DateTime} from "luxon";

const DELIVERY_FILTER_KEY = 'delivery-filter';
export const EMPTY_DELIVERY_FILTER: DeliveryFilter = {
        states: [],
        carriers: [],
        services: [],
        cubes: []
    };

class UiStore {

    constructor() {
        if (!localStorage.getItem(DELIVERY_FILTER_KEY)) {
            localStorage.setItem(DELIVERY_FILTER_KEY, JSON.stringify(EMPTY_DELIVERY_FILTER));
        }
    }

    getDeliveryFilter() {
        const json = localStorage.getItem(DELIVERY_FILTER_KEY);
        if (json) {
            const filter = JSON.parse(json) as DeliveryFilter;
            return {
                ...filter,
                from: filter.from ? DateTime.fromISO(filter.from as any) : undefined,
                until: filter.until ? DateTime.fromISO(filter.until as any) : undefined
            };
        }
        return EMPTY_DELIVERY_FILTER;
    }

    saveDeliveryFilter(filter: DeliveryFilter) {
        localStorage.setItem(DELIVERY_FILTER_KEY, JSON.stringify(filter));
    }
}

export const uiStore = new UiStore();
