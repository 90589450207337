import * as React from "react";
import {createElement} from "react";
import {QueueStatus} from "../../domain/types";
import {Chip} from "@material-ui/core";
import {green, grey, red} from "@material-ui/core/colors";

interface QueueEventStateComponentProps {
    state: QueueStatus;
    size?: 'small' | 'medium';
}

export function QueueEventStateComponent(props: QueueEventStateComponentProps) {
    const {state, size} = props;
    let color;
    switch (state) {
        case QueueStatus.Created:
            color = { backgroundColor: grey[500], color: '#FFFFFF' };
            break;
        case QueueStatus.Succeeded:
            color = { backgroundColor: green[500], color: '#FFFFFF' };
            break;
        case QueueStatus.Failed:
            color = { backgroundColor: red[500], color: '#FFFFFF' };
            break;
    }
    return (<Chip label={state} style={{...color}} size={size} />);
}
