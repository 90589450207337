import {Api} from "../Api";
import {Box, Cube, CubeDownTime, CubeDto, CubeState, Page, Pageable, SetBoxStateRequest} from "./types";
import {paging} from "./paging";

export class CubesProvider {

    static list(tenantId: string, pageable?: Pageable) {
        if(pageable) {
            return Api.GET<Page<Cube>>(paging.attachToUrl(pageable, `tenants/${tenantId}/cubes`));
        }else {
            return Api.GET<Page<Cube>>(`tenants/${tenantId}/cubes?unpaged=true`);
        }
    }

    static get(tenantId: string, cubeId: string, omitCache?: boolean) {
        let url = `tenants/${tenantId}/cubes/${cubeId}`;
        if(omitCache) {
            url += '/non-cached';
        }
        return Api.GET<CubeDto>(url);
    }

    static setImage(tenantId: string, cubeId: string, imageUrl: string) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/image`, { imageUrl });
    }

    static setCarrierAccessCode(tenantId: string, cubeId: string, carrierId: string, accessCode: string, pin: string) {
        const request = {
            carrierId: carrierId,
            accessCode: accessCode,
            pin: pin
        };
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/carrier-access-code`, request);
    }

    static setCubeSettings(tenantId: string, cubeId: string, carrierId: string, alphanumericMachineId?: string, state?: CubeState, routeCode?: string, serviceAreaCode?: string, carrierEmail?: string) {
        const request = {
            carrierId,
            alphanumericMachineId,
            state: state || CubeState.Enabled,
            routeCode,
            serviceAreaCode,
            carrierEmail
        };
        return Api.PUT<CubeDto>(`tenants/${tenantId}/cubes/${cubeId}/cube-settings`, request);
    }

    static putActivatedCarriers(tenantId: string, cubeId: string, carrierIds: string[]) {
        const request = {
            carrierIds
        };
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/activated-carriers`, request);
    }

    static setDownTimes(tenantId: string, cubeId: string, downtimes: CubeDownTime[]) {
        downtimes = downtimes
            .map(t => {
                t.startDate = t.startDate.toJSDate().toISOString() as any;
                t.endDate = t.endDate.toJSDate().toISOString() as any;
                return t;
            });
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/downtimes`, downtimes);
    }

    static listBoxes(tenantId: string, cubeId: string) {
        return Api.GET<Box[]>(`tenants/${tenantId}/cubes/${cubeId}/boxes`);
    }

    static getBox(tenantId: string, cubeId: string, boxNumber: string) {
        return Api.GET<Box>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}`);
    }

    static openAllBoxes(tenantId: string, cubeId: string) {
        return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/open-all-boxes`, {});
    }

    static openBox(tenantId: string, cubeId: string, boxNumber: string) {
        return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/open`, {});
    }

    static setBoxState(tenantId: string, cubeId: string, boxNumber: string, request: SetBoxStateRequest) {
        return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/set-state`, request);
    }
}
