import {Page, Pageable} from "./types";

const StorageKey = 'paging';
const DefaultPageSize = 25;
const DefaultPageSizes = [ 25, 50, 100 ];

interface StoredPagingSettings {
    pageSize: number
}

class Paging {

    pagingSettings: StoredPagingSettings;

    constructor() {
        let storedPagingSettingsString = localStorage.getItem(StorageKey);
        if(storedPagingSettingsString) {
            this.pagingSettings = JSON.parse(storedPagingSettingsString);
        }else{
            this.pagingSettings = {
                pageSize: DefaultPageSize
            }
        }
    }

    private storeSettings() {
        localStorage.setItem(StorageKey, JSON.stringify(this.pagingSettings));
    }

    storePageSize(pageSize: number) {
        this.pagingSettings.pageSize = pageSize;
        this.storeSettings();
    }

    emptyPage(): Page<{}> {
        return {
            content: [],
            totalPages: 0,
            totalElements: 0,
            number: 0,
            numberOfElements: 0,
            size: 0,
            last: false,
            first: false,
            hasContent: false,
            hasNext: false,
            hasPrevious: false
        };
    }

    defaultPageable(): Pageable {
        return {
            pageNumber: 0,
            pageSize: this.pagingSettings.pageSize
        };
    }

    pageable(page: number) {
        return {
            pageNumber: page,
            pageSize: this.pagingSettings.pageSize
        }
    }

    pageSizes(selectedPageSize: number): number[] {
        const containsSelectedPagesSize = DefaultPageSizes.filter(s => s == selectedPageSize).length > 0;
        if(containsSelectedPagesSize) {
            return DefaultPageSizes;
        }else{
            let pageSizes = DefaultPageSizes;
            pageSizes.push(selectedPageSize);
            return pageSizes.sort((a, b) => a - b);
        }
    }

    apply(page: Page<any>, params: { pageNumber?: number, pageSize?: number, sort?: string, direction?: "asc" | "desc" }): Pageable {
        if(params.pageSize) {
            this.storePageSize(params.pageSize);
        }
        return {
            pageNumber: params.pageNumber != undefined ? params.pageNumber : page.number,
            pageSize: params.pageSize != undefined ? params.pageSize : page.size,
            sort: params.sort,
            direction: params.direction,
        };
    }

    attachToUrl({pageNumber, pageSize, sort, direction}: Pageable, url: string): string {
        const separator = url.indexOf('?') != -1 ? '&' : '?';
        let path = `${url}${separator}page=${pageNumber}&size=${pageSize}`;
        if (sort) {
            path += `&sort=${sort},${direction || 'desc'}`;
        }
        return path;
    }

}

export const paging = new Paging();
