import red from '@material-ui/core/colors/red';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";

export const gs = 3;

// A custom theme for this app
const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#05164d',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#0a2b95',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f5f5f5',
    },
  }
});

export default customTheme;

export const useStyles = makeStyles({
  successButton: {
    background: green[500],
    color: '#fff',
    border: green[700],
    '&:hover': {
      background: green[700]
    }
  },
  dangerButton: {
    background: red[500],
    color: '#fff',
    border: red[700],
    '&:hover': {
      background: red[700]
    }
  }
});
