import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@material-ui/core";
import {gs} from "../../theme";
import {messages} from "../../i18n";
import {CarrierToken, CarrierTokenCreateResponse} from "../../domain/types";
import {ApiError} from "../../Api";
import {CarrierTokensProvider} from "../../domain/CarrierTokensProvider";
import {userStore} from "../../domain/UserStore";
import {Loading} from "../Loading";
import {BlockSvg} from "../BlockSvg";
import buildingBlocks from "../../assets/undraw_building_blocks_n0nc.svg";
import {DateComponent} from "../DateComponent";
import {ErrorPopup} from "../ErrorPopup";

interface CarrierTokensProps {
    carrierId: string;
}

export function CarrierTokens(props: CarrierTokensProps) {
    const {carrierId} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [tokens, setTokens] = useState<CarrierToken[]>();
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [creationMode, setCreationMode] = useState<boolean>(false);
    const [createForm, setCreateForm] = useState<{ carrierId: string }>({ carrierId: '' });
    const [createResult, setCreateResult] = useState<CarrierTokenCreateResponse|undefined>(undefined);

    useEffect(() => {
        load();
    }, [setTokens, setError]);

    const load = () => {
        setLoading(true);
        CarrierTokensProvider.listForCarrier(userStore.getTenantId(), carrierId)
            .then(page => {
                setTokens(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const toggleCreationMode = () => {
        setCreateForm({ carrierId: '' });
        setCreationMode(!creationMode);
    }

    const create = () => {
        CarrierTokensProvider.create(userStore.getTenantId(), carrierId)
            .then(result => {
                load();
                toggleCreationMode();
                setCreateResult(result);
            })
            .catch(toggleError);
    }

    const closeResult = () => {
        setCreateResult(undefined);
    }

    return (
        <Grid container spacing={gs}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item style={{flexGrow: 1}}>
                        <Typography variant="h5" gutterBottom>{messages.carrierTokens.plural}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" onClick={toggleCreationMode}>{messages.carrierTokens.create.action}</Button>
                    </Grid>
                </Grid>
            </Grid>
            {!tokens &&
            <Grid item xs={12}>
                <Loading />
            </Grid>
            }
            {tokens &&
            <Grid item xs={12}>
                <Paper>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{messages.carrierTokens.uuid}</TableCell>
                                    <TableCell width="20%">{messages.carrierTokens.createdAt}</TableCell>
                                    <TableCell width="20%">{messages.carrierTokens.lastUsedAt}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tokens.length == 0 && (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Box p={3}>
                                            <Typography align="center">{messages.carrierTokens.noTokens}</Typography>
                                        </Box>
                                        <BlockSvg src={buildingBlocks} />
                                        <Box p={6} />
                                    </TableCell>
                                </TableRow>
                                )}
                                {tokens.map(token => (
                                    <TableRow key={token.uuid} style={{ cursor: 'pointer' }}>
                                        <TableCell>{token.uuid}</TableCell>
                                        <TableCell><DateComponent date={token.createdAt} /></TableCell>
                                        <TableCell>
                                            {token.lastUsedAt && <DateComponent date={token.lastUsedAt} />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            }

            <Dialog open={creationMode} fullWidth maxWidth="sm">
                <DialogTitle>{messages.carrierTokens.create.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined" disabled
                                       value={carrierId}
                                       label={messages.carrierTokens.carrierId}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCreationMode}>{messages.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={create}>{messages.create}</Button>
                </DialogActions>
            </Dialog>

            {createResult && (
                <Dialog open={true} fullWidth maxWidth="sm">
                    <DialogTitle>{messages.carrierTokens.create.result}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth variant="outlined"
                                           value={createResult.carrierId}
                                           label={messages.carrierTokens.carrierId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth variant="outlined"
                                           value={createResult.rawToken}
                                           label={messages.carrierTokens.apiToken}
                                           helperText={messages.carrierTokens.apiTokenHint}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeResult}>{messages.close}</Button>
                    </DialogActions>
                </Dialog>
            )}

            {error && (
            <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </Grid>
    );
}
