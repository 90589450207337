import {createElement, useEffect, useState} from "react";
import {ContainerLayout} from "../ContainerLayout";
import {
    Box,
    Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, LinearProgress,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@material-ui/core";
import {messages} from "../../i18n";
import {Crumb} from "../Crumb";
import {Carrier, CarrierCreateRequest, Page} from "../../domain/types";
import {Loading} from "../Loading";
import React from 'react';
import buildingBlocks from "../../assets/undraw_building_blocks_n0nc.svg";
import {BlockSvg} from "../BlockSvg";
import {TablePagePagination} from "../base/TablePagePagination";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {userStore} from "../../domain/UserStore";
import {paging} from "../../domain/paging";
import {ApiError} from "../../Api";
import {AddFab} from "../AddFab";
import {ErrorPopup} from "../ErrorPopup";
import {RouteComponentProps} from "react-router";

export const CarriersList = (props: RouteComponentProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [carriers, setCarriers] = useState<Page<Carrier>|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [createForm, setCreateForm] = useState<CarrierCreateRequest>({ id: '', name: '' });
    const [creationMode, setCreationMode] = useState<boolean>(false);

    const load = () => {
        CarrierProvider.list(userStore.getTenantId(), paging.defaultPageable())
            .then(page => {
                setCarriers(page);
                setLoading(false)
            })
            .catch(toggleError);
    };

    useEffect(() => {
        load();
    }, []);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const toggleCreationMode = () => {
        setCreateForm({ id: '', name: '' });
        setCreationMode(!creationMode);
    };

    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        setCreateForm({
            ...createForm,
            [name]: value
        });
    }

    const create = () => {
        CarrierProvider.create(userStore.getTenantId(), createForm)
            .then(result => {
                load();
                toggleCreationMode();
            })
            .catch(toggleError);
    }

    return (
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.carriers.plural}/>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Typography variant="h4" gutterBottom>{messages.carriers.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {carriers == undefined && (
                            <Box p={3}>
                                <Box p={1}/>
                                <Loading/>
                            </Box>
                        )}
                        {carriers != undefined && (
                            <React.Fragment>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell>{messages.carriers.name}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {carriers.numberOfElements == 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Box p={3}>
                                                            <Typography align="center">{messages.carriers.noCarriers}</Typography>
                                                        </Box>
                                                        <BlockSvg src={buildingBlocks}/>
                                                        <Box p={6} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {carriers.content.map(carrier => (
                                                <TableRow key={carrier.id} style={{ cursor: 'pointer' }} onClick={() => props.history.push('/carriers/' + carrier.id)}>
                                                    <TableCell>{carrier.id}</TableCell>
                                                    <TableCell>{carrier.name}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagePagination page={carriers} onPagingChanged={load}/>
                                {loading && (
                                    <LinearProgress variant="indeterminate"/>
                                )}
                            </React.Fragment>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={toggleCreationMode}/>

            <Dialog open={creationMode} fullWidth maxWidth="sm">
                <DialogTitle>{messages.carriers.create.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                       name="id"
                                       value={createForm.id}
                                       onChange={onInputChange}
                                       label="Id" required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth variant="outlined"
                                       name="name"
                                       value={createForm.name}
                                       onChange={onInputChange}
                                       label={messages.carriers.name}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleCreationMode}>{messages.cancel}</Button>
                    <Button variant="contained" color="primary" onClick={create} disabled={!createForm.id}>{messages.create}</Button>
                </DialogActions>
            </Dialog>

            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )
}
