import * as React from "react";
import {createElement} from "react";
import {DeliveryState} from "../../domain/types";
import {Chip} from "@material-ui/core";
import {messages} from "../../i18n";
import {amber, blueGrey, green} from "@material-ui/core/colors";

interface DeliveryStateComponentProps {
    state: DeliveryState;
    size?: 'small' | 'medium';
    icon?: React.ReactElement;
}

export function DeliveryStateComponent(props: DeliveryStateComponentProps) {
    const {state, size, icon} = props;
    let color;
    switch (state) {
        case DeliveryState.Created:
            color = { backgroundColor: blueGrey[300], color: '#000000' };
            break;
        case DeliveryState.Stored:
            color = { backgroundColor: green.A400, color: '#000000' };
            break;
        case DeliveryState.PickedUp:
            color = { backgroundColor: green[900], color: '#FFFFFF' };
            break;
        case DeliveryState.Canceled:
        case DeliveryState.Retrieved:
            color = { backgroundColor: amber[500], color: '#FFFFFF' };
            break;
    }
    return (<Chip label={messages.deliveries.stateEnum[state]} style={{...color}} size={size} icon={icon} />);
}
