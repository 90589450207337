import {Component, createElement} from "react";
import {Fab} from "@material-ui/core";
import React = require("react");
import {AddIcon} from "../icons";
import customTheme from "../theme";

interface AddFabProps {
    onClick: () => void
}

export class AddFab extends Component<AddFabProps> {

    render() {
        return(
            <Fab color="primary" aria-label="add"
                 style={{
                     position: 'fixed',
                     bottom: customTheme.spacing(8),
                     right: customTheme.spacing(3)
                 }}
                 onClick={this.props.onClick}>
                <AddIcon />
            </Fab>
        );
    }
}
