import * as React from "react";
import {createElement} from "react";
import {paging} from "../../domain/paging";
import {TablePagination} from "@material-ui/core";
import {Page, Pageable} from "../../domain/types";

interface TablePagePaginationProps {
    page: Page<any>;
    pageable?: Pageable;
    onPagingChanged: (pageable: Pageable) => void;
}

export function TablePagePagination(props: TablePagePaginationProps) {
    const {page, pageable, onPagingChanged} = props;

    const handleChangePage = (e: unknown, pageNumber: number) => {
        onPagingChanged(paging.apply(page, { pageNumber, sort: pageable?.sort, direction: pageable?.direction }));
    };

    const handleChangeRowsPerPage = (e: any) => {
        if(e) {
            onPagingChanged(paging.apply(page, { pageSize: e.target.value,sort: pageable?.sort, direction: pageable?.direction }));
        }
    };

    return (
        <TablePagination
            rowsPerPageOptions={paging.pageSizes(page.size)}
            component="div"
            count={page.totalElements}
            rowsPerPage={page.size}
            page={page.number}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );
}
