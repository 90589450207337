import * as React from "react";
import {createElement, useEffect, useState} from "react";
import {WebAuth} from "auth0-js";
import {Redirect, RouteComponentProps} from "react-router";
import {ContainerLayout} from "./components/ContainerLayout";
import {Loading} from "./components/Loading";
import {messages} from "./i18n";
import {Alert} from "@material-ui/lab";
import {Button, Grid, Typography} from "@material-ui/core";
import {auth0Config} from "./auth0.config";

export const redirectUri = `${location.protocol}//${location.host}/auth`;
export const returnToUri = `${location.protocol}//${location.host}/`;
export const auth0 = new WebAuth({
    domain: auth0Config.domain,
    clientID: auth0Config.clientId,
    redirectUri: redirectUri,
    audience: auth0Config.audience,
    responseType: 'token id_token',
    scope: 'openid'
});


const StorageKey = 'auth';

type StoredAuth = {
    accessToken:string;
    idToken:string;
    expiresAt?: number;
}

function isNotExpired(auth:StoredAuth) {
    return !auth.expiresAt || new Date().getTime() < auth.expiresAt;
}

class AuthStorage {

    store(accessToken:string, idToken:string, expiresIn?:number) {
        // compute expiresAt
        const expiresAt = expiresIn && (expiresIn * 1000) + new Date().getTime();
        // store to localStorage
        localStorage.setItem(StorageKey, JSON.stringify({
            accessToken,
            idToken,
            expiresAt
        } as StoredAuth));
    }

    clear() {
        localStorage.removeItem(StorageKey);
    }

    logout() {
        this.clear();
        auth0.logout({
            clientID: auth0Config.clientId,
            returnTo: returnToUri,
        });
    }

    private readAuth() {
        const item = localStorage.getItem(StorageKey);
        return item && JSON.parse(item) as StoredAuth;
    }

    get accessToken() {
        const auth = this.readAuth();
        if (auth && isNotExpired(auth)) {
            return auth.accessToken;
        }
        else {
            return undefined;
        }
    }

    get idToken() {
        const auth = this.readAuth();
        if (auth && isNotExpired(auth)) {
            return auth.idToken;
        }
        else {
            return undefined;
        }
    }

    get authenticated() {
        const auth = this.readAuth();
        if (auth) {
            return isNotExpired(auth);
        }
        else {
            return false;
        }
    }
}

export const authStorage = new AuthStorage();

export const AuthLoginPath = '/login';
export const AuthLogoutPath = '/logout';
export const AuthCallbackProcessingPath = '/auth';
export const AuthCallbackErrorPath = '/auth-error';

// Authentication callback

interface AuthCallbackRouteProps extends RouteComponentProps<{ }> { }

export function AuthCallbackRoute(props: AuthCallbackRouteProps) {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        auth0.parseHash((err, authResult) => {
            // console.log('Got auth result', authResult);

            if (err) {
                console.log('Could not process authentication callback', err);
                console.log(err);
                setError(err.description || 'Unknown error');
                return;
            }

            if (authResult && authResult.accessToken && authResult.idToken) {
                console.log('Processing auth result');
                authStorage.store(authResult.accessToken, authResult.idToken, authResult.expiresIn);
                setSuccess(true);
            }
        });
    }, [setError, setSuccess]);

    if(!error && !success) {
        return(
            <ContainerLayout>
                <Loading message={messages.auth.processing} />
            </ContainerLayout>
        );
    }
    if(success) {
        // changed from / to /tenants, because of double login problem, change of authStorage.authenticated does not re-render router
        return (<Redirect to="/tenants" />);
    }
    return (<AuthCallbackError message={error} />);
}

// Authentication Error

interface AuthCallbackErrorProps { message?: string }

function AuthCallbackError(props: AuthCallbackErrorProps) {

    return(
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">{messages.auth.error}</Typography>
                </Grid>
                {props.message &&
                <Grid item xs={12}>
                    <Alert severity="error">{props.message}</Alert>
                </Grid>
                }
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={(_e) => window.location.href = AuthLoginPath}>{messages.auth.login}</Button>
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}

// Redirect to Auth0 Login

interface AuthLoginProps extends RouteComponentProps<{ }> { }

export function AuthLogin(props: AuthLoginProps) {
    useEffect(() => {
        auth0.authorize();
    });

    return(
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1" align="center">{messages.auth.loginTitle}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Loading message={messages.auth.loginInfo} />
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}

// Processing Auth0 Logout

interface AuthLogoutProps extends RouteComponentProps<{ }> { }

export function AuthLogout(props: AuthLogoutProps) {

    useEffect(() => {
        localStorage.clear();
        authStorage.clear();
        auth0.logout({
            clientID: auth0Config.clientId,
            returnTo: returnToUri
        });
    });

    return(
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1" align="center">{messages.auth.logoutTitle}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Loading message={messages.auth.logoutInfo} />
                </Grid>
            </Grid>
        </ContainerLayout>
    );
}
