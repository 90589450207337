import * as React from "react";
import {createElement} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    small: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '400px',
        maxHeight: '400px',
        width: '40%',
        height: '40%'
    },
    large: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '400px',
        maxHeight: '400px'
    }
}));

interface BlockSvgProps {
    src: string;
    large?: boolean;
}

export function BlockSvg({src, large}: BlockSvgProps) {
    const classes = useStyles();
    return(<img className={large ? classes.large : classes.small} src={src} />);
}
