import * as React from "react";
import {createElement} from "react";
import {Chip, Theme} from "@material-ui/core";
import {messages} from "../../i18n";
import {makeStyles} from "@material-ui/core/styles";
import {green, red} from "@material-ui/core/colors";

interface BooleanDisplayProps {
    value: boolean;
    size?: 'small' | 'medium';
    reverseColors?: boolean;
}

export function BooleanDisplay(props: BooleanDisplayProps) {
    const {value, size, reverseColors} = props;
    const classes = useStyles();
    const colorValue = reverseColors ? !value : value;
    const className = colorValue ? classes.yes : classes.no;
    return (
        <Chip label={value ? messages.enabled : messages.disabled} size={size} className={className} />
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    yes: {
        backgroundColor: green[400]
    },
    no: {
        backgroundColor: red[500],
        color: '#fff'
    }
}));
