import * as React from "react";
import {createElement} from "react";
import {Chip} from "@material-ui/core";
import {messages} from "../../i18n";
import {green, red} from "@material-ui/core/colors";

interface CarrierNotificationStateComponentProps {
    disabledCarrierNotification?: boolean;
    size?: 'small' | 'medium';
}

export function CarrierNotificationStateComponent(props: CarrierNotificationStateComponentProps) {
    const {disabledCarrierNotification, size} = props;
    const disabledColor = { backgroundColor: red[500], color: '#FFFFFF' };
    const enabledColor = { backgroundColor: green[500], color: '#FFFFFF' };
    const color = disabledCarrierNotification ? disabledColor : enabledColor;
    const label = disabledCarrierNotification ? messages.disabled : messages.enabled;
    return (<Chip label={label} style={{...color}} size={size} />);
}
