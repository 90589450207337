import * as React from "react";
import {createElement, PropsWithChildren} from "react";
import {Grid} from "@material-ui/core";
import {LabeledData} from "./LabeledData";

interface LabeledItemProps extends PropsWithChildren<{label: string}>{
}

export function LabeledItem(props: LabeledItemProps) {
    const {label, children} = props;
    return (
        <Grid item>
            <LabeledData label={label}>{children}</LabeledData>
        </Grid>
    );
}
