import {Carrier, Cube, DeliveryFilter, DeliveryFilterWrapper, DeliveryService, DeliveryState} from "../../domain/types";
import {createElement, useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    FormControl,
    Grid,
    IconButton, Input, InputLabel, ListItemText, MenuItem, Select, TextField, Typography
} from "@material-ui/core";
import {gs} from "../../theme";
import {FilterListIcon} from "../../icons";
import {lookupByString, messages} from "../../i18n";
import {enumKeys} from "../../tools";
import {CubesProvider} from "../../domain/CubesProvider";
import {userStore} from "../../domain/UserStore";
import {DateTime} from "luxon";
import {DatePicker} from "@material-ui/pickers";
import {CarrierProvider} from "../../domain/CarrierProvider";

interface DeliveryListFilterProps {
    filter: DeliveryFilter;
    onChange: (filter: DeliveryFilter) => void;
}

export const DeliveryListFilter = ({filter, onChange}: DeliveryListFilterProps) => {
    const [edit, setEdit] = useState<boolean>(false);

    const handleApply = (filter: DeliveryFilter) => {
        onChange(filter);
        setEdit(false);
    };

    const clearArray = (key: string) => {
        onChange({
            ...filter,
            [key]: []
        });
    };

    const clearFulltext = () => {
        onChange({
            ...filter,
            fulltext: undefined
        });
    };

    const clearTimestamp = () => {
        onChange({
            ...filter,
            from: undefined,
            until: undefined
        });
    };

	const wrapper = new DeliveryFilterWrapper(filter);

    return (
        <Grid container spacing={gs}>
            <Grid item>
                <IconButton onClick={() => setEdit(true)}><FilterListIcon/></IconButton>
            </Grid>
            <Grid item>
                <Box paddingTop={1}>
					<Grid container spacing={gs}>
						{wrapper.empty &&
							<Grid item>
								<Chip variant="outlined" label={messages.deliveries.filter.empty} onClick={() => setEdit(true)} />
							</Grid>
						}
						{filter.fulltext &&
							<Grid item>
								<Chip label={filter.fulltext} onDelete={clearFulltext}/>
							</Grid>
						}
						{(filter.from && filter.until) &&
							<Grid item>
								<TimestampDisplay from={filter.from} until={filter.until} onClear={() => clearTimestamp()}/>
							</Grid>
						}
						{(filter.states && filter.states.length > 0) &&
							<Grid item>
                    			<StatesDisplay states={filter.states} onClear={() => clearArray('states')}/>
							</Grid>
						}
						{(filter.carriers && filter.carriers.length > 0) &&
							<Grid item>
                    			<CarriersDisplay carriers={filter.carriers} onClear={() => clearArray('carriers')}/>
							</Grid>
						}
						{(filter.services && filter.services.length > 0) &&
							<Grid item>
                    			<ServicesDisplay services={filter.services} onClear={() => clearArray('services')}/>
							</Grid>
						}
						{(filter.cubes && filter.cubes.length > 0) &&
							<Grid item>
                    			<CubesDisplay cubes={filter.cubes} onClear={() => clearArray('cubes')}/>
							</Grid>
						}
					</Grid>
                </Box>
            </Grid>
            {edit && (
                <FilterForm open={edit} filter={filter} onClose={() => setEdit(false)} onSubmit={handleApply} />
            )}
        </Grid>
    )
}

interface BaseDisplayProps {
    onClear: () => void;
}

interface StatesDisplayProps extends BaseDisplayProps {
    states: DeliveryState[];
}

const StatesDisplay = ({states, onClear}: StatesDisplayProps) => {
    if (states.length == 0) {
        return null;
    }
    const label = states
        .map(s => lookupByString(messages.deliveries.stateEnum, s))
        .join(', ');
    return (
        <Chip label={label} onDelete={onClear} />
    )
}

interface CarriersDisplayProps extends BaseDisplayProps {
    carriers: string[];
}

const CarriersDisplay = ({carriers, onClear}: CarriersDisplayProps) => {
    if (carriers.length == 0) {
        return null;
    }
    return (
        <Chip label={carriers.join(', ')} onDelete={onClear}/>
    )
}

interface ServicesDisplayProps extends BaseDisplayProps {
    services: string[];
}

const ServicesDisplay = ({services, onClear}: ServicesDisplayProps) => {
    if (services.length == 0) {
        return null;
    }
    const label = services
        .map(s => lookupByString(messages.deliveries.serviceEnum, s))
        .join(', ');
    return (
        <Chip label={label} onDelete={onClear}/>
    )
}

interface CubesDisplayProps extends BaseDisplayProps {
    cubes: string[];
}

const CubesDisplay = ({cubes, onClear}: CubesDisplayProps) => {
    if (cubes.length == 0) {
        return null;
    }
    return (
        <Chip label={cubes.join(', ')} onDelete={onClear}/>
    )
}

interface TimestampDisplayProps extends BaseDisplayProps {
    from?: DateTime;
    until?: DateTime;
}

const TimestampDisplay = ({from, until, onClear}: TimestampDisplayProps) => {
    if (!from && !until) {
        return null;
    }
    const ds = [
        from ? from.toFormat('dd.MM.yyyy') : undefined,
        until ? until.toFormat('dd.MM.yyyy') : undefined
    ];
    return (
        <Chip label={ds.filter(d => d).join(' - ')} onDelete={onClear}/>
    )
}


interface FilterFormProps {
	open: boolean;
    filter: DeliveryFilter;
    onClose: () => void;
    onSubmit: (filter: DeliveryFilter) => void;
}

const FilterForm = ({open, onClose, onSubmit, filter}: FilterFormProps) => {
    const [cubeList, setCubeList] = useState<Cube[]>([]);
    const [carrierList, setCarrierList] = useState<Carrier[]>([]);
    const [fulltext, setFulltext] = useState<string>(filter.fulltext || '');
    const [from, setFrom] = useState<DateTime|undefined>(filter.from);
    const [until, setUntil] = useState<DateTime|undefined>(filter.until);
    const [states, setStates] = useState<DeliveryState[]>(filter.states);
    const [carriers, setCarriers] = useState<string[]>(filter.carriers);
    const [services, setServices] = useState<DeliveryService[]>(filter.services);
    const [cubes, setCubes] = useState<string[]>(filter.cubes);

    useEffect(() => {
        CubesProvider.list(userStore.getTenantId())
            .then(l => setCubeList(l.content));
    }, []);

    useEffect( () => {
        CarrierProvider.list(userStore.getTenantId())
            .then(c => setCarrierList(c.content));
    }, [])

    const isStateChecked = (state: string) => states.indexOf(state as DeliveryState) != -1;
    const isCarrierChecked = (carrier: string) => carriers.indexOf(carrier) != -1;
    const isServiceChecked = (service: string) => services.indexOf(service as DeliveryService) != -1;
    const isCubeChecked = (cube: string) => cubes.indexOf(cube) != -1;

    const handleSubmit = () => {
        onSubmit({
            states,
            carriers,
            services,
            cubes,
            fulltext,
            from,
            until
        });
    }

    return (
        <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
            <DialogTitle>{messages.filter}</DialogTitle>
            <DialogContent>
                <Grid container spacing={gs}>
                    <Grid item sm={12}>
                        <TextField fullWidth variant="outlined"
                                   label={messages.deliveries.filter.fulltext}
                                   value={fulltext} onChange={e => setFulltext(e.target.value)}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="body2">{messages.deliveries.filter.timestamp}</Typography>
                        <Box mb={2}/>
                        <Grid container spacing={gs}>
                            <Grid item sm={6}>
                                <DatePicker inputVariant="outlined" autoFocus fullWidth
                                                label={messages.deliveries.filter.from} format="dd.MM.yyyy"
                                                value={from} onChange={dt => setFrom(dt as DateTime)} />
                            </Grid>
                            <Grid item sm={6}>
                                <DatePicker inputVariant="outlined" autoFocus fullWidth
                                                label={messages.deliveries.filter.until} format="dd.MM.yyyy"
                                                minDate={from}
                                                value={until} onChange={dt => setUntil(dt as DateTime)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{messages.deliveries.state}</InputLabel>
                            <Select
                                multiple
                                value={states}
                                onChange={e => setStates(e.target.value as any)}
                                input={<Input />}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {enumKeys(DeliveryState).map(s => (
                                    <MenuItem key={s} value={s}>
                                        <Checkbox checked={isStateChecked(s)} />
                                        <ListItemText primary={lookupByString(messages.deliveries.stateEnum, s)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{messages.deliveries.carrier}</InputLabel>
                            <Select
                                multiple
                                value={carriers}
                                onChange={e => setCarriers(e.target.value as any)}
                                input={<Input />}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {carrierList.map(c => (
                                    <MenuItem key={c.id} value={c.id}>
                                        <Checkbox checked={isCarrierChecked(c.id)} />
                                        <ListItemText primary={c.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{messages.deliveries.service}</InputLabel>
                            <Select
                                multiple
                                value={services}
                                onChange={e => setServices(e.target.value as any)}
                                input={<Input/>}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {enumKeys(DeliveryService).map(s => (
                                    <MenuItem key={s} value={s}>
                                        <Checkbox checked={isServiceChecked(s)} />
                                        <ListItemText primary={lookupByString(messages.deliveries.serviceEnum, s)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{messages.cubes.singular}</InputLabel>
                            <Select
                                multiple
                                value={cubes}
                                onChange={e => setCubes(e.target.value as any)}
                                input={<Input/>}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {cubeList.map(c => (
                                    <MenuItem key={c.cubeId} value={c.cubeId}>
                                        <Checkbox checked={isCubeChecked(c.cubeId)} />
                                        <ListItemText primary={c.description || c.cubeId} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{messages.cancel}</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>{messages.confirm}</Button>
            </DialogActions>
        </Dialog>

    )
}
