import {RouteComponentProps} from "react-router";
import {createElement, useCallback, useEffect, useState} from "react";
import {CubeDto, CubeDownTime, Page, Carrier, CubeSettingDto} from "../../domain/types";
import {CubesProvider} from "../../domain/CubesProvider";
import {ContainerLayout} from "../ContainerLayout";
import {Box, Breadcrumbs, Button, Divider, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {Loading} from "../Loading";
import {gs} from "../../theme";
import {LabeledData} from "../LabeledData";
import {CubeConnectedComponent} from "./CubeConnectedComponent";
import {CubeUtilizationComponent} from "./CubeUtilizationComponent";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {BoxesList} from "./boxesList";
import {DateComponent, Display} from "../DateComponent";
import {DateTimePicker} from '@material-ui/pickers';
import {DateTime} from "luxon";
import {userStore} from "../../domain/UserStore";
import {CubeAccessCodesComponent} from "./CubeAccessCodesComponent";
import {CubeSettingsList} from "./cubeSettingsList";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {ActivatedCarriersComponent} from "./ActivatedCarriersComponent";

export const CubeDetails = (props: RouteComponentProps<{ cubeId: string }>) => {
    const [cube, setCube] = useState<CubeDto|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [editImageMode, setEditImageMode] = useState<boolean>(false);
    const [loadingCarriers, setLoadingCarriers] = useState<boolean>(false);
    const [carriers, setCarriers] = useState<Page<Carrier>|undefined>(undefined);
    const [editDownTimeMode, setEditDownTimeMode] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [downTimes, setDownTimes] = useState<CubeDownTime[]>([]);

    useEffect(() => {
        fetchCube(props.match.params.cubeId);
        fetchCarriers();
    }, [setCube, setImageUrl, setDownTimes]);

    const fetchCube = (cubeId: string) => {
        CubesProvider.get(userStore.getTenantId(), cubeId, true)
            .then(cube => {
                setCube(cube);
                setImageUrl(cube.imageUrl);
                setDownTimes(cube.downTimes.map((t, i) => {
                    t.startDate = DateTime.fromISO(t.startDate as any);
                    t.endDate = DateTime.fromISO(t.endDate as any);
                    t.key = new Date().toISOString() + i;
                    return t;
                }));
            })
            .catch(toggleError);
    };

    const saveSettings = useCallback(async (cubeSetting: CubeSettingDto) => {
        const { cubeId, carrierId, alphanumericMachineId, state, routeCode, serviceAreaCode, carrierEmail } = cubeSetting;
        try {
            const newCube = await CubesProvider.setCubeSettings(userStore.getTenantId(), cubeId!, carrierId!, alphanumericMachineId, state, routeCode, serviceAreaCode, carrierEmail);
            setCube(newCube);
            setImageUrl(newCube?.imageUrl);
            setDownTimes(newCube.downTimes.map((t, i) => {
                t.startDate = DateTime.fromISO(t.startDate as any);
                t.endDate = DateTime.fromISO(t.endDate as any);
                t.key = new Date().toISOString() + i;
                return t;
            }));
        } catch (err) {
            toggleError(err);
        }
    }, []);

    const fetchCarriers = () => {
        setLoadingCarriers(true);
        CarrierProvider.list(userStore.getTenantId(), { pageNumber: 0, pageSize: 100 })
            .then(page => {
                setCarriers(page);
                setLoadingCarriers(false);
            })
            .catch(toggleError);
    };


    const onImageChange = (event: any) => {
        setImageUrl(event.target.value);
    };

    const setImage = () => {
        if (cube) {
            CubesProvider.setImage(userStore.getTenantId(), cube.cubeId, imageUrl)
                .then(() => {
                    toggleEditImageMode();
                    fetchCube(cube.cubeId);
                })
                .catch(toggleError);
        }
    };

    const dateTimeChange = (prop: 'startDate' | 'endDate', key: string, date: DateTime) => {
        setDownTimes(downTimes.map(t => {
            if (t.key === key) {
                t[prop] = date;
            }
            return t;
        }));
    };

    const onCommentChange = (key: string, comment: string) => {
        setDownTimes(downTimes.map(t => {
            if (t.key === key) {
                t.comment = comment;
            }
            return t;
        }));
    };

    const appendDownTime = () => {
        setDownTimes(downTimes.concat({
            startDate: DateTime.local(),
            endDate: DateTime.local(),
            comment: '',
            key: new Date().toISOString() + (downTimes.length + 1)
        }));
    };

    const deleteDownTime = (key: string) => {
        setDownTimes(downTimes.filter(t => t.key !== key));
    };

    const saveDownTimes = () => {
        if (cube) {
            CubesProvider.setDownTimes(userStore.getTenantId(), cube.cubeId, downTimes)
                .then(() => {
                    toggleEditDownTimeMode();
                    fetchCube(cube.cubeId);
                })
                .catch(toggleError);
        }
    };

    const toggleEditDownTimeMode = () => {
        setEditDownTimeMode(!editDownTimeMode);
    };

    const toggleEditImageMode = () => {
        setEditImageMode(!editImageMode);
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            {!cube && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {cube && (
                <div>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb label={messages.cubes.plural} route="/cubes" />
                                <Crumb label={cube.description} />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" color="secondary" gutterBottom>
                                        {messages.cubes.singular}: {cube.description}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {/*<ItemActions onCreateRental={() => props.history.push(getRentalTriggerUrlWithItemUuid(item.uuid))}*/}
                                    {/*             onEditItem={toggleEditMode}*/}
                                    {/*             onDeleteItem={toggleDelete}/>*/}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <CubeHeader cube={cube}/>
                                    <Box my={3}/>
                                    <Paper>
                                        <Box p={gs}>
                                            <Grid container spacing={gs}>
                                                <Grid item md={4} xs={12}>
                                                    <Grid container spacing={gs}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1"><strong>{messages.cubes.image}</strong></Typography>
                                                            {!editImageMode && (
                                                                cube.imageUrl ? <img src={cube.imageUrl} style={{ width: '100%' }} /> : messages.notAvailable
                                                            )}
                                                            {editImageMode && (
                                                                <Box my={2}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item style={{ flexGrow: 1 }}>
                                                                            <TextField fullWidth variant="outlined" size="small"
                                                                                       label={messages.cubes.image} value={imageUrl}
                                                                                       onChange={onImageChange}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button variant="outlined" onClick={setImage}>{messages.save}</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            )}
                                                            <Box my={2}/>
                                                            <Button size="small" variant="outlined" onClick={toggleEditImageMode}>{editImageMode ? messages.cancel : messages.edit}</Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ActivatedCarriersComponent cube={cube} carriers={carriers} loadingCarriers={loadingCarriers} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <Typography variant="subtitle1"><strong>{messages.cubes.downtimes}</strong></Typography>
                                                    {!editDownTimeMode && (
                                                        <div>
                                                            {cube.downTimes.length == 0 && messages.notAvailable}
                                                            {cube.downTimes.map((time, i) => (
                                                                <div key={'downtime-' + i}>
                                                                    <Typography variant="body1">{time.comment}</Typography>
                                                                    <DateComponent date={time.startDate} display={Display.Timestamp} /> - <DateComponent date={time.endDate} display={Display.Timestamp} />
                                                                    <Box my={1}><Divider/></Box>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {editDownTimeMode && (
                                                        <Box my={2}>
                                                            {downTimes.map(time => (
                                                                <Box key={time.key} my={2}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item style={{ flexGrow: 1 }}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item md={6} xs={12}>
                                                                                    <DateTimePicker size="small" inputVariant="outlined" autoFocus fullWidth ampm={false}
                                                                                                    label={messages.cubes.startDate} format="MMMM dd yyyy HH:mm"
                                                                                                    minutesStep={60}
                                                                                                    value={time.startDate} onChange={dt => dateTimeChange('startDate', time.key, dt as DateTime)} />
                                                                                </Grid>
                                                                                <Grid item md={6} xs={12}>
                                                                                    <DateTimePicker size="small" inputVariant="outlined" autoFocus fullWidth ampm={false}
                                                                                                    label={messages.cubes.endDate} format="MMMM dd yyyy HH:mm"
                                                                                                    minutesStep={60}
                                                                                                    value={time.endDate} onChange={dt => dateTimeChange('endDate', time.key, dt as DateTime)} />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                    <TextField fullWidth variant="outlined" size="small"
                                                                                               label={messages.cubes.comment} value={time.comment}
                                                                                               onChange={e => onCommentChange(time.key, e.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button size="small" variant="outlined" onClick={() => deleteDownTime(time.key)}>{messages.delete}</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Box my={1}><Divider/></Box>
                                                                </Box>
                                                            ))}
                                                            <Button size="small" variant="outlined" onClick={appendDownTime}>{messages.add}</Button>
                                                        </Box>
                                                    )}
                                                    <Box my={2}/>
                                                    <Button size="small" variant="outlined" onClick={toggleEditDownTimeMode}>{editDownTimeMode ? messages.cancel : messages.edit}</Button>
                                                    &nbsp;
                                                    {editDownTimeMode && <Button size="small" variant="outlined" onClick={saveDownTimes}>{messages.save}</Button>}
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <CubeAccessCodesComponent cube={cube} onChanged={() => fetchCube(cube.cubeId)} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <Typography variant="subtitle1"><strong>{messages.cubes.address}</strong></Typography>
                                            <Grid container spacing={3}>
                                                <Grid item style={{flexGrow: 1}}>
                                                    <LabeledData label={messages.cubes.street}>
                                                        {cube.street}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item style={{flexGrow: 1}}>
                                                    <LabeledData label={messages.cubes.houseNumber}>
                                                        {cube.houseNumber}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.cubes.postalCode}>
                                                        {cube.postalCode}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item style={{flexGrow: 1}}>
                                                    <LabeledData label={messages.cubes.city}>
                                                        {cube.city}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.cubes.country}>
                                                        {cube.countryCode}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.cubes.latitude}>
                                                        {cube.latitude}
                                                    </LabeledData>
                                                </Grid>
                                                <Grid item>
                                                    <LabeledData label={messages.cubes.longitude}>
                                                        {cube.longitude}
                                                    </LabeledData>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <CubeSettingsList cube={cube} saveSettings={saveSettings}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <BoxesList cube={cube}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
};

function CubeHeader(props: { cube: CubeDto }) {
    const {cube} = props;
    return (
        <Paper>
            <Box p={gs}>
                <Grid container spacing={gs}>
                    <Grid item>
                        <LabeledData label={messages.cubes.connectionState}>
                            <CubeConnectedComponent connected={cube.connected} />
                        </LabeledData>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                        <LabeledData label={messages.cubes.utilization.label}>
                            <CubeUtilizationComponent utilization={cube.utilization} />
                        </LabeledData>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
