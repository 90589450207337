import {createElement, useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {CssBaseline, Grid, MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import customTheme from "./theme";
import {
    AuthCallbackProcessingPath,
    AuthCallbackRoute,
    AuthLogin,
    AuthLoginPath,
    AuthLogout,
    AuthLogoutPath, authStorage
} from "./Auth";
import {NotFound} from "./components/NotFound";
import FooterBar from "./components/FooterBar";
import {NavBar} from "./components/NavBar";
import {DeliveriesList, DeliveryCreate, DeliveryDetails} from "./components/deliveries";
import {BoxDetails, CubeDetails, CubesList} from "./components/cubes";
import {userStore} from "./domain/UserStore";
import {UserDetails, UsersList} from "./components/users";
import {TenantSelector} from "./components/TenantSelector";
import {CarrierDetails, CarrierEdit, CarriersList} from "./components/carriers";
import {StatisticsView} from "./components/statistics/StatisticsView";

export const App = () => {
    const [hasTenant, setHasTenant] = useState<boolean>(false);

    useEffect(() => {
        if (authStorage.authenticated && userStore.tenant) {
            setHasTenant(true);
            userStore.load()
                .then();
        }
    }, [setHasTenant]);

    const handleTenantSelected = () => {
        setHasTenant(true);
        userStore.load()
            .then();
    };

    return (
        <MuiThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <CssBaseline />
                <BrowserRouter>
                    <Grid container direction="column">
                        <Grid item>
                            {hasTenant && (
                                <NavBar />
                            )}
                        </Grid>
                        <Grid item style={{flexGrow: 1}}>
                            <Switch>
                                <Redirect exact from="/" to={authStorage.authenticated ? '/tenants' : AuthLoginPath} />

                                <Route exact path={AuthLoginPath} component={AuthLogin} />
                                <Route exact path={AuthLogoutPath} component={AuthLogout} />
                                <Route exact path={AuthCallbackProcessingPath} component={AuthCallbackRoute} />

                                <Route exact path="/tenants" render={(props) => <TenantSelector onTenantSelected={handleTenantSelected} {...props}/>} />

                                <Route exact path="/deliveries" component={DeliveriesList} />
                                <Route exact path="/deliveries/create" component={DeliveryCreate} />
                                <Route exact path="/deliveries/:deliveryId" component={DeliveryDetails} />

                                <Route exact path="/cubes" component={CubesList} />
                                <Route exact path="/cubes/:cubeId" component={CubeDetails} />
                                <Route exact path="/cubes/:cubeId/boxes/:boxNumber" component={BoxDetails} />

                                <Route exact path="/users" component={UsersList} />
                                <Route exact path="/users/:userId" component={UserDetails} />

                                {userStore.root && [
                                    <Route key="carrier-list" exact path="/carriers" component={CarriersList} />,
                                    <Route key="carrier-details" exact path="/carriers/:carrierId" component={CarrierDetails} />,
                                    <Route key="carrier-edit" exact path="/carriers/:carrierId/edit" component={CarrierEdit} />
                                ]}

                                <Route exact path="/statistics" component={StatisticsView} />

                                <Route component={NotFound} />
                            </Switch>
                        </Grid>
                        <Grid item style={{display: 'flex', flexDirection: 'column-reverse'}}>
                            {hasTenant && (
                                <FooterBar />
                            )}
                        </Grid>
                    </Grid>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};
