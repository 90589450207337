import {ContainerLayout} from "../ContainerLayout";
import {createElement, useEffect, useState} from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid, Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {gs} from "../../theme";
import {Carrier, Cube, DeliveryCreateRequest} from "../../domain/types";
import {userStore} from "../../domain/UserStore";
import {CubesProvider} from "../../domain/CubesProvider";
import {ApiError} from "../../Api";
import {RouteComponentProps} from "react-router";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {ErrorPopup} from "../ErrorPopup";
import {CarrierProvider} from "../../domain/CarrierProvider";
import {CarrierSelectInput} from "../base/CarrierSelectInput";

export const DeliveryCreate = (props: RouteComponentProps) => {
    const [cubes, setCubes] = useState<Cube[]>([]);
    const [boxTypes, setBoxTypes] = useState<string[]|undefined>(undefined);
    const [form, setForm] = useState<DeliveryCreateRequest>({
        carrierId: '',
        cubeId: '',
        boxType: '',
        parcelNumber: '',
        disableCarrierNotification: false,
    });
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [carrier, setCarriers] = useState<Carrier[]>([]);

    useEffect(() => {
        CubesProvider.list(userStore.getTenantId())
            .then(p => setCubes(p.content))
            .catch(toggleError)
    }, [setCubes]);

    useEffect( () => {
        CarrierProvider.list(userStore.getTenantId())
            .then(p => setCarriers(p.content))
            .catch(toggleError)
    },[setCarriers]);

    const onInputChange = (event: any) => {
        const {name, value} = event.target;
        setForm({
            ...form,
            [name]: value
        });
        if (name == 'cubeId') {
            onCubeChange(value);
        }
    };

    const handleCarrierIdChange = (carrierId: string) => {
        setForm({
            ...form,
            carrierId: carrierId
        });
    };

    const onCheckboxChange = (event: any) => {
        const {name, checked} = event.target;
        setForm({
            ...form,
            [name]: checked
        });
    };

    const onCubeChange = (cubeId: string) => {
        if (cubeId) {
            CubesProvider.listBoxes(userStore.getTenantId(), cubeId)
                .then(boxes => {
                    const boxTypes = [];
                    for (let box of boxes) {
                        for (let type of box.types) {
                            if (boxTypes.indexOf(type) < 0) {
                                boxTypes.push(type);
                            }
                        }
                    }
                    setBoxTypes(boxTypes);
                })
                .catch(toggleError);
        } else {
            setForm({
                ...form,
                boxType: ''
            });
        }

    };

    const create = () => {
        setProcessing(true);
        DeliveriesProvider.create(userStore.getTenantId(), form)
            .then(d => props.history.push(`/deliveries/${d.id}`))
            .catch(toggleError);
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
        setProcessing(false);
    };

    return (
        <ContainerLayout>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.deliveries.plural} route="/deliveries" />
                        <Crumb label={messages.deliveries.create.title} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" color="secondary" gutterBottom>
                                {messages.deliveries.create.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <Grid container spacing={gs}>
                                <Grid item md={3} sm={12}>
                                    <CarrierSelectInput label={messages.carriers.singular} carrierId={form.carrierId} onChange={handleCarrierIdChange} />
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel>{messages.cubes.singular}</InputLabel>
                                        <Select name="cubeId"
                                                value={form.cubeId}
                                                onChange={onInputChange}
                                                label={messages.cubes.singular}
                                        >
                                            <MenuItem value={''}>{messages.deliveries.create.selectCube}</MenuItem>
                                            {cubes.map(c => <MenuItem key={c.cubeId} value={c.cubeId}>{c.description || c.cubeId}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} sm={12}>
                                    <FormControl variant="outlined" fullWidth disabled={!boxTypes} required>
                                        <InputLabel>{form.cubeId ? messages.boxes.singular : messages.deliveries.create.selectCubeFirst}</InputLabel>
                                        <Select name="boxType"
                                                value={form.boxType}
                                                onChange={onInputChange}
                                                label={form.cubeId ? messages.boxes.singular : messages.deliveries.create.selectCubeFirst}
                                        >
                                            <MenuItem value={''}>{messages.deliveries.create.selectBox}</MenuItem>
                                            {boxTypes && boxTypes.map(b => <MenuItem key={b} value={b}>{b}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box my={1}/>
                            <Grid container spacing={gs}>
                                <Grid item md={9}>
                                    <TextField variant="outlined" fullWidth label={messages.deliveries.parcelNumber}
                                               name="parcelNumber" required
                                               value={form.parcelNumber}
                                               placeholder="0623012345678"
                                               onChange={onInputChange} />
                                </Grid>
                                <Grid item md={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.disableCarrierNotification}
                                                onChange={onCheckboxChange}
                                                name="disableCarrierNotification"
                                                color="primary"
                                            />
                                        }
                                        label={messages.deliveries.disableCarrierNotification}
                                    />
                                </Grid>
                            </Grid>
                            <Box my={1}/>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button variant="contained" color="primary"
                                            disabled={processing || !form.carrierId || !form.cubeId || !form.boxType || !form.parcelNumber}
                                            onClick={create}>{messages.create}</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => props.history.push("/deliveries")}>{messages.cancel}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    )

};
