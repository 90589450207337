import * as React from "react";
import {createElement} from "react";
import {Chip, makeStyles} from "@material-ui/core";
import {CubeState} from "../../domain/types";
import {messages} from "../../i18n";
import {amber, green, red} from "@material-ui/core/colors";

interface CubeStateDisplayProps {
    state: CubeState;
}

export function CubeStateDisplay(props: CubeStateDisplayProps) {
    const {state} = props;
    const classes = useStyles();
    return (
        <Chip label={messages.cubes.states[state]} className={classes[state]} />
    );
}

const useStyles = makeStyles((theme) => ({
    Enabled: {
        backgroundColor: green[500]
    },
    FirstMileOnly: {
        backgroundColor: amber[500]
    },
    LastMileOnly: {
        backgroundColor: amber[500]
    },
    Disabled: {
        backgroundColor: red[600],
        color: "white"
    }
}));