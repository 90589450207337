import {RouteComponentProps} from "react-router";
import {createElement, useEffect, useState} from "react";
import {ContainerLayout} from "../ContainerLayout";
import {Box as CubeBox, Occupancy} from "../../domain/types";
import {CubesProvider} from "../../domain/CubesProvider";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {Loading} from "../Loading";
import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {gs} from "../../theme";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {Alert, AlertTitle} from "@material-ui/lab";
import {LabeledData} from "../LabeledData";
import {CubeBoxLockStateComponent} from "./CubeBoxLockStateComponent";
import {CubeBoxAvailableComponent} from "./CubeBoxAvailableComponent";
import {DeliveryLinkComponent} from "../deliveries/DeliveryLinkComponent";
import {CubeBoxActionsComponent} from "./CubeBoxActionsComponent";
import {ConfirmDialog} from "../ConfirmDialog";
import {userStore} from "../../domain/UserStore";

export const BoxDetails = (props: RouteComponentProps<{ cubeId: string, boxNumber: string }>) => {
    const {cubeId, boxNumber} = props.match.params;
    const [box, setBox] = useState<CubeBox|undefined>(undefined);
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [openBoxMode, setOpenBoxMode] = useState<boolean>(false);
    const [enableBoxMode, setEnableBoxMode] = useState<boolean>(false);
    const [disableBoxMode, setDisableBoxMode] = useState<boolean>(false);

    useEffect(() => {
        fetchBox(cubeId, boxNumber);
    }, [setBox]);

    const fetchBox = (cubeId: string, boxNumber: string) => {
        CubesProvider.getBox(userStore.getTenantId(), cubeId, boxNumber)
            .then(setBox)
            .catch(toggleError);
    }


    const toggleOpenBox = () => {
        setOpenBoxMode(!openBoxMode);
    };

    const toggleEnableBox = () => {
        setEnableBoxMode(!enableBoxMode);
    };

    const toggleDisableBox = () => {
        setDisableBoxMode(!disableBoxMode);
    };

    const openBox = () => {
        if (box) {
            CubesProvider.openBox(userStore.getTenantId(), cubeId, box.number)
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleOpenBox();
        }
    };

    const enableBox = () => {
        if (box) {
            CubesProvider.setBoxState(userStore.getTenantId(), cubeId, box.number, { disabled: false })
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleEnableBox();
        }
    };

    const disableBox = () => {
        if (box) {
            CubesProvider.setBoxState(userStore.getTenantId(), cubeId, box.number, { disabled: true })
                .then(() => fetchBox(cubeId, box.number))
                .catch(toggleError);
            toggleDisableBox();
        }
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    return (
        <ContainerLayout>
            {!box && (
                <Box p={5}>
                    <Loading/>
                </Box>
            )}
            {box && (
                <div>
                    <Grid container spacing={gs}>
                        <Grid item xs={12}>
                            <Breadcrumbs>
                                <Crumb label={messages.cubes.plural} route="/cubes" />
                                <Crumb label={cubeId} route={`/cubes/${cubeId}`} />
                                <Crumb label={box.description} />
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item style={{flexGrow: 1}}>
                                    <Typography variant="h4" color="secondary" gutterBottom>
                                        {messages.boxes.singular}: {box.description}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CubeBoxActionsComponent disabledBox={box.disabled}
                                                             onOpenBox={toggleOpenBox}
                                                             onDisableBox={toggleDisableBox}
                                                             onEnableBox={toggleEnableBox} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={gs}>
                                {box.disabled && (
                                    <Grid item xs={12}>
                                        <Alert severity="warning"
                                               action={<Button size="small" variant="contained" color="primary" onClick={toggleEnableBox}>{messages.cubes.boxes.enableBox}</Button>}>
                                            <AlertTitle>{messages.cubes.boxes.disabled}</AlertTitle>
                                            {messages.cubes.boxes.disabledInfo}
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <BoxHeader box={box}/>
                                </Grid>
                                <Grid item xs={12}>
                                    {box.occupancies && box.occupancies.length > 0 ?
                                        <OccupancyTable occupancies={box.occupancies} /> :
                                        <Alert severity="info">{messages.cubes.boxes.noOccupancies}</Alert>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {openBoxMode && (
                        <ConfirmDialog
                            title={messages.cubes.boxes.openBox}
                            message={(box.occupancies && box.occupancies.length > 0) ? messages.cubes.boxes.openBoxInfoOccupied(box.description) : messages.cubes.boxes.openBoxInfoEmpty(box.description)}
                            confirmLabel={messages.cubes.boxes.openBox}
                            resolve={openBox}
                            reject={toggleOpenBox}
                        />
                    )}
                    {enableBoxMode && (
                        <ConfirmDialog
                            title={messages.cubes.boxes.enableBox}
                            message={messages.cubes.boxes.enableBoxInfo(box.description)}
                            confirmLabel={messages.cubes.boxes.enableBox}
                            resolve={enableBox}
                            reject={toggleEnableBox}
                        />
                    )}
                    {disableBoxMode && (
                        <ConfirmDialog
                            title={messages.cubes.boxes.disableBox}
                            message={messages.cubes.boxes.disableBoxInfo(box.description)}
                            confirmLabel={messages.cubes.boxes.disableBox}
                            resolve={disableBox}
                            reject={toggleDisableBox}
                        />
                    )}
                </div>
            )}
            {error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
};

const BoxHeader = (props: { box: CubeBox}) => {
    const {box} = props;
    return (
        <Paper>
            <Box p={gs}>
                <Grid container spacing={gs}>
                    <Grid item>
                        <LabeledData label={messages.cubes.boxes.lockState.label}>
                            <CubeBoxLockStateComponent lockState={box.lockStatus} />
                        </LabeledData>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                        <LabeledData label={messages.cubes.boxes.available}>
                            <CubeBoxAvailableComponent available={!box.disabled} />
                        </LabeledData>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const OccupancyTable = (props: { occupancies: Occupancy[] }) => {
    return (
        <Paper>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>{messages.cubes.boxes.occupancy}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.occupancies.map((occupancy) => (
                    <TableRow key={occupancy.uuid}>
                        <TableCell>
                            <DeliveryLinkComponent id={occupancy.deliveryUuid} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </Paper>
    )
};
