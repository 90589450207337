import * as React from "react";
import {createElement, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core";

interface SignatureComponentProps {
    signature: string;
    isPicture: boolean;
}

interface SignatureLine {
    x: number[];
    y: number[];
}

function getMinAndDifDimensions(lines: SignatureLine[]) {
    let minX = 100000000000000;
    let minY = 100000000000000;
    let maxX = 0;
    let maxY = 0;

    for (let line of lines) {
        for (let i = 1; i < line.x.length; i++) {
            minX = Math.min(minX, line.x[i]);
            minY = Math.min(minY, line.y[i]);
            maxX = Math.max(maxX, line.x[i]);
            maxY = Math.max(maxY, line.y[i]);
        }
    }

    const difX = maxX - minX;
    const difY = maxY - minY;
    return {minX, minY, difX, difY};
}

function getScalingAndOffset(difY: number, difX: number,  ratio: number, height: number, width: number) {
    let scalingFactor;
    let offsetX = 0;
    let offsetY = 0;
    if (difY * ratio > difX) {
        scalingFactor = height / difY;
        offsetX = (width - (difX * scalingFactor)) / 2;
    } else {
        scalingFactor = width / difX;
        offsetY = (height - (difY * scalingFactor)) / 2;
    }
    return {scalingFactor, offsetX, offsetY};
}

function DrawLine(ctx: CanvasRenderingContext2D, line: SignatureLine, minX: number, offsetX: number, minY: number, offsetY: number, scalingFactor: number) {
    ctx.beginPath();
    ctx.moveTo((line.x[0] - minX) * scalingFactor + offsetX, (line.y[0] - minY) * scalingFactor + offsetY);
    for (let i = 1; i < line.x.length; i++) {
        ctx.lineTo((line.x[i] - minX) * scalingFactor + offsetX, (line.y[i] - minY) * scalingFactor + offsetY);
    }
    ctx.stroke();
}

function DrawLines(ctx: CanvasRenderingContext2D, lines: SignatureLine[],  minX: number, offsetX: number, minY: number, offsetY: number, scalingFactor: number) {
    lines.forEach(line => DrawLine(ctx, line, minX, offsetX, minY, offsetY, scalingFactor));
}

export function SignatureComponent(props: SignatureComponentProps) {
    const classes = useStyles();
    const signature = props.signature.startsWith('data:image/') ? props.signature : `data:image/png;base64,${props.signature}`;
    const refCanvas = useRef<HTMLCanvasElement>(null)

    useEffect(()=>{
        const canvas = refCanvas.current;
        if(!canvas)
            return;

        const lines : SignatureLine[] = JSON.parse(props.signature);
        const ctx = canvas.getContext('2d')!;

        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 2;

        const width = canvas.width;
        const height = canvas.height;
        const ratio = width/height;

        let {minX, minY, difX, difY} = getMinAndDifDimensions(lines);
        let {scalingFactor, offsetX, offsetY} = getScalingAndOffset(difY, difX, ratio, height, width);

        DrawLines(ctx, lines, minX, offsetX, minY, offsetY, scalingFactor);
    },[signature])

    if(props.isPicture){
        return (
            <img className={classes.signature} src={signature} />
        );
    }
    else{
        return (
            <canvas ref={refCanvas} >
            </canvas>
        )
    }
}

const useStyles = makeStyles({
    signature: {
        display: 'inline-block',
        maxWidth: '100%'
    }
});
