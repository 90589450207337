import * as React from "react";
import {createElement} from "react";
import {useState} from "react";
import {useEffect} from "react";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {ContainerLayout} from "../ContainerLayout";
import {
    Box,
    Breadcrumbs,
    Grid, LinearProgress,
    Paper, TableContainer,
    Toolbar,
    Typography
} from "@material-ui/core";
import {Crumb} from "../Crumb";
import {messages} from "../../i18n";
import {Loading} from "../Loading";
import {AddFab} from "../AddFab";
import {Delivery, DeliveryFilter, Page, Pageable} from "../../domain/types";
import {paging} from "../../domain/paging";
import {RouteComponentProps} from "react-router";
import {ApiError} from "../../Api";
import {ErrorPopup} from "../ErrorPopup";
import {userStore} from "../../domain/UserStore";
import {TablePagePagination} from "../base/TablePagePagination";
import {availableColumns, defaultColumns, DeliveriesTable} from "./DeliveriesTable";
import {TableColumnFilter} from "../base/TableColumnFilter";
import {gs} from "../../theme";
import {DeliveryListFilter} from "./DeliveryListFilter";
import {EMPTY_DELIVERY_FILTER, uiStore} from "../../domain/UiStore";

export function DeliveriesList(props: RouteComponentProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [pageable, setPageable] = useState<Pageable>({ ...paging.defaultPageable(), sort: 'created', direction: 'desc' });
    const [deliveries, setDeliveries] = useState<Page<Delivery>|undefined>(undefined);
    const [filter, setFilter] = useState<DeliveryFilter>(uiStore.getDeliveryFilter());
    const [error, setError] = useState<ApiError|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultColumns);

    const load = (filter: DeliveryFilter, p: Pageable) => {
        setLoading(true);
        DeliveriesProvider.list(userStore.getTenantId(), filter, p)
            .then((page) => {
                setDeliveries(page);
                setLoading(false);
            })
            .catch(toggleError);
    };

    useEffect(() => {
       load(filter, pageable);
    }, [setDeliveries]);

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSelectedColumns = (columns: string[]) => {
        setSelectedColumns(columns);
    }

    const handleFilter = (filter: DeliveryFilter) => {
        setFilter(filter);
        uiStore.saveDeliveryFilter(filter);
        load(filter, pageable);
    }

    const handleSortTableChange = (sort: string, direction: 'asc' | 'desc') => {
        if (filter) {
            const p = {
                ...pageable,
                sort,
                direction
            };
            setPageable(p);
            load(filter, p);
        }
    }

    return (
        <ContainerLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <Crumb label={messages.deliveries.plural} />
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h4" gutterBottom>{messages.deliveries.plural}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        {deliveries == undefined && (
                            <Box p={3}>
                                <Box p={1} />
                                <Loading />
                            </Box>
                        )}
                        {deliveries != undefined &&
                        <React.Fragment>
                            <Toolbar>
                                <Grid container spacing={gs}>
                                    <Grid item style={{flexGrow: 1}}>
                                        <DeliveryListFilter filter={filter} onChange={handleFilter}/>
                                    </Grid>
                                    <Grid item>
                                        <TableColumnFilter availableColumns={availableColumns} selectedColumns={selectedColumns}
                                                           onChange={(columns) => handleSelectedColumns(columns)} />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <TableContainer>
                                <DeliveriesTable deliveries={deliveries} columns={selectedColumns as any}
                                                 onOpen={d => props.history.push('/deliveries/' + d.id)}
                                                 onSortChange={handleSortTableChange}
												 onResetFilter={() => handleFilter(EMPTY_DELIVERY_FILTER)}
                                />
                            </TableContainer>
                            <TablePagePagination pageable={pageable} page={deliveries} onPagingChanged={p => load(filter, p)} />
                            {loading &&
                            	<LinearProgress variant="indeterminate" />
                            }
                        </React.Fragment>
                        }
                    </Paper>
                </Grid>
            </Grid>
            <AddFab onClick={() => props.history.push('/deliveries/create')} />
            { error && (
                <ErrorPopup url={error.url} statusCode={error.code} onCloseHandler={() => toggleError(undefined)}/>
            )}
        </ContainerLayout>
    );
}
