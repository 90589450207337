import {createElement} from "react";
import {useHistory} from "react-router";
import {Button, Toolbar, Typography, Hidden, AppBar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {VCLogo} from "./VCLogo";
import {messages} from "../i18n";
import {userStore} from "../domain/UserStore";

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 5000,
        backgroundColor: '#ffffff'
    },
    navHome: {
        flexGrow: 1,
        color: '#05164d',
        fontWeight: 'bold',
        paddingLeft: '0.4em'
    }
});

export const NavBar = () => {
    const history = useHistory();

    const handleNavClick = (path: string) => {
        history.push(path);
    };

    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <VCLogo />
                <Hidden smDown>
                    <Typography variant="h5" color="inherit" className={classes.navHome}
                                onClick={() => handleNavClick('/')}>{`${messages.appName}`}</Typography>
                </Hidden>
                <Hidden mdUp>
                    <Typography color="inherit" style={{flexGrow: 1}}/>
                </Hidden>
                <Button color="secondary" onClick={() => handleNavClick('/deliveries')}>{messages.deliveries.plural}</Button>
                <Button color="secondary" onClick={() => handleNavClick('/cubes')}>{messages.cubes.plural}</Button>
                <Button color="secondary" onClick={() => handleNavClick('/users')}>{messages.users.plural}</Button>
                <Button color="secondary" onClick={() => handleNavClick('/carriers')}>{messages.carriers.plural}</Button>
                <Button color="secondary" onClick={() => handleNavClick('/statistics')}>{messages.statistics.plural}</Button>
            </Toolbar>
        </AppBar>
    );
};
