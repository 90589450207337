import {Carrier, CarrierCreateRequest, CarrierUpdateRequest, Page, Pageable} from "./types";
import {paging} from "./paging";
import {Api} from "../Api";

export class CarrierProvider {

    static list(tenantId: string, pageable?: Pageable) {
        if(pageable) {
            return Api.GET<Page<Carrier>>(paging.attachToUrl(pageable, `tenants/${tenantId}/carriers`));
        }else{
            return Api.GET<Page<Carrier>>('tenants/${tenantId}/carriers?unpaged=true');
        }
    }

    static create(tenantId: string, request: CarrierCreateRequest) {
        return Api.POST<Carrier>(`tenants/${tenantId}/carriers`, request);
    }

    static get(tenantId: string, carrierId: string) {
        return Api.GET<Carrier>(`tenants/${tenantId}/carriers/${carrierId}`);
    }

    static update(tenantId: string, carrierId: string, request: CarrierUpdateRequest) {
        return Api.PUT<Carrier>(`tenants/${tenantId}/carriers/${carrierId}`, request);
    }

    static delete(tenantId: string, carrierId: string) {
        return Api.DELETE(`tenants/${tenantId}/carriers/${carrierId}`);
    }
}