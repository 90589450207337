import * as React from "react";
import {Component, createElement} from "react";
import {Box, Container} from "@material-ui/core";

interface ContainerLayoutProps { }

interface ContainerLayoutState { }

export class ContainerLayout extends Component<ContainerLayoutProps, ContainerLayoutState> {

    render() {
        return (
            <Container>
                <Box marginTop={5}>{ this.props.children }</Box>
            </Container>
        );
    }
}